import useChainId from '@/hooks/useChainId'
import { type ReactElement } from 'react'
import ImageFallback from '../ImageFallback'
import css from './styles.module.css'

const FALLBACK_ICON = '/images/common/token-placeholder.svg'

const fallbacks: { [chainId: string]: string } = {
  '137': '/images/common/matic-token-placeholder.svg',
}

const TokenIcon = ({
  logoUri,
  tokenSymbol,
  size = 26,
  fallbackSrc,
  showBackground = false,
}: {
  logoUri?: string
  tokenSymbol?: string
  size?: number
  fallbackSrc?: string
  showBackground?: boolean
}): ReactElement => {
  const chainId = useChainId()
  return (
    <ImageFallback
      src={logoUri}
      alt={tokenSymbol}
      fallbackSrc={fallbackSrc || fallbacks[chainId] || FALLBACK_ICON}
      height={size}
      className={css.image}
      style={
        showBackground
          ? { background: 'var(--Supporting-Dark-BG-700, #272A3E)', padding: '8px', borderRadius: '6px' }
          : {}
      }
    />
  )
}

export default TokenIcon
