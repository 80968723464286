import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { parsePrefixedAddress } from '@/utils/addresses'

const useSafeAddress = (): string => {
  const router = useRouter()
  const { wallet = '' } = router.query
  const fullAddress = Array.isArray(wallet) ? wallet[0] : wallet

  const checksummedAddress = useMemo(() => {
    if (!fullAddress) return ''
    const { address } = parsePrefixedAddress(fullAddress)
    return address
  }, [fullAddress])

  return checksummedAddress
}

export default useSafeAddress
