import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import type {
  WalletInit,
  EIP1193Provider,
  RequestPatch,
  EIP712Request,
  ProviderAccounts,
  Balance,
  ChainId,
} from '@web3-onboard/common'
import { ProviderRpcError } from '@web3-onboard/common'
import { getRpcServiceUrl } from '@/hooks/wallets/web3'
import { walletFromMnemonic } from '@/utils/wallet'
import { JsonRpcProvider } from '@ethersproject/providers'
import { type TransactionRequest } from '@ethersproject/abstract-provider'
import { Wallet } from 'ethers'

export const WALLET_NAME = 'HD Wallet'

type RequestPatchV2 = RequestPatch & {
  eth_signTypedData_v4?:
    | ((args: { baseRequest: EIP1193Provider['request']; params: EIP712Request['params'] }) => Promise<string>)
    | null
}

interface JsonRpcResponse {
  id: string | undefined
  jsonrpc: '2.0'
  method: string
  result?: ProviderAccounts | Balance | ProviderAccounts | ChainId | null
  error?: string
}

const createRequest = (provider: any): EIP1193Provider['request'] =>
  (({ method, params }) =>
    new Promise((resolve, reject) => {
      provider.sendAsync(
        {
          id: 0,
          jsonrpc: '2.0',
          method,
          params,
        },
        (error: string, { result }: JsonRpcResponse) => {
          if (error) {
            reject(JSON.parse(error))
          } else {
            resolve(result == undefined ? null : (result as any))
          }
        },
      )
    })) as EIP1193Provider['request']

const createEIP1193Provider = (provider: any, requestPatch?: RequestPatchV2): EIP1193Provider => {
  let baseRequest: any
  if (provider.request) {
    // Copy the original request method and bind the provider context to it
    baseRequest = provider.request.bind(provider)
  } else if (provider.sendAsync) {
    baseRequest = createRequest(provider)
  }

  const request: EIP1193Provider['request'] = async ({ method, params }) => {
    const key = method as keyof RequestPatchV2

    // If the request method is set to null
    // this indicates this method is not supported
    if (requestPatch && requestPatch[key] === null) {
      throw new ProviderRpcError({
        code: 4200,
        message: `The Provider does not support the requested method: ${method}`,
      })
    }

    if (requestPatch && requestPatch[key]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // @TODO - Fix this type error
      return requestPatch[key]({ baseRequest, params })
    } else if (baseRequest) {
      return baseRequest({ method, params })
    } else {
      throw new ProviderRpcError({
        code: 4200,
        message: `The Provider does not support the requested method: ${method}`,
      })
    }
  }
  provider.request = request

  return provider
}

const hdWalletModule = (mnemonic: string, chain: ChainInfo, addressIndex: number = 0): WalletInit => {
  return () => {
    return {
      label: WALLET_NAME,
      getIcon: async () => {
        return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.3484 5.54239L21.2494 0.319753C20.4751 -0.106584 19.5249 -0.106584 18.7506 0.319753L3.65156 5.54239C2.87725 5.96873 2.40211 6.75689 2.40211 7.60956V12.7088H5.92169V9.55613C5.92169 8.70065 6.39683 7.91249 7.17114 7.48615L18.7506 4.21008C19.5249 3.78375 20.4751 3.78375 21.2494 4.21008L32.8289 7.48615C33.6032 7.91249 34.0783 8.70065 34.0783 9.55613V12.7088H37.5979V7.60956C37.5979 6.75689 37.1227 5.96873 36.3484 5.54239ZM21.2494 35.7899C20.4751 36.2191 19.5249 36.2191 18.7506 35.7927C14.9582 33.6975 11.8639 30.7524 9.66417 27.294H5.0242C7.2298 31.5939 10.7435 35.2598 15.231 37.7365L18.7506 39.6802C19.5249 40.1066 20.4751 40.1066 21.2494 39.6802L24.769 37.7365C29.2565 35.2598 32.7702 31.5939 34.9758 27.294H30.3358C28.139 30.7524 25.0447 33.6975 21.2494 35.7899Z"
              fill="#5D5DFF"
            />
            <path
              d="M24.8394 17.3003V22.6997L24.596 22.8343L20 25.4007L15.4011 22.8343L15.1606 22.6997V17.3003L20 14.6021L24.8394 17.3003Z"
              fill="#22C55E"
            />
            <path
              d="M35.1606 24.6294C37.8333 24.6294 40 22.5574 40 20.0014C40 17.4454 37.8333 15.3734 35.1606 15.3734C32.4878 15.3734 30.3212 17.4454 30.3212 20.0014C30.3212 22.5574 32.4878 24.6294 35.1606 24.6294Z"
              fill="#22C55E"
            />
            <path
              d="M4.83942 24.6294C7.51216 24.6294 9.67884 22.5574 9.67884 20.0014C9.67884 17.4454 7.51216 15.3734 4.83942 15.3734C2.16668 15.3734 0 17.4454 0 20.0014C0 22.5574 2.16668 24.6294 4.83942 24.6294Z"
              fill="#22C55E"
            />
          </svg>`
      },
      getInterface: async ({ EventEmitter, chains }) => {
        const { getHardwareWalletProvider } = await import('@web3-onboard/hw-common')

        // const { default: HDWalletProvider } = await import('@truffle/hdwallet-provider')

        let currentChain = chains[0]
        const url = currentChain.rpcUrl || getRpcServiceUrl(chain.publicRpcUri)
        const jsonProvider = new JsonRpcProvider(url)
        // const web3 = new Web3(new Web3.providers.HttpProvider(url))

        // const provider = new HDWalletProvider({
        //   mnemonic,
        //   providerOrUrl: url,
        //   addressIndex,
        // })
        const hdProvider = getHardwareWalletProvider(() => url)
        const activeWallet = walletFromMnemonic(mnemonic, addressIndex)

        const walletProvider = new Wallet(activeWallet.privateKey, jsonProvider)

        walletProvider.signMessage

        const eventEmitter = new EventEmitter()

        const eip1193Provider = createEIP1193Provider(hdProvider, {
          eth_requestAccounts: async () => {
            return [walletProvider.address]
          },
          eth_selectAccounts: async () => {
            return [walletProvider.address]
          },
          eth_accounts: async () => {
            return [walletProvider.address]
          },
          eth_sendTransaction: async ({ params }) => {
            const nonce = await walletProvider.getTransactionCount()
            const feeData = await walletProvider.getFeeData()
            const gasLimit = BigInt(params[0].gas || '0x0')
            const gasPrice = feeData.gasPrice?.toHexString() || '0x0'

            const tx: TransactionRequest = {
              nonce,
              from: activeWallet.address,
              to: params[0].to,
              value: params[0].value,
              data: params[0].data,
              gasLimit,
              gasPrice,
            }
            const receipt = await walletProvider.sendTransaction(tx)
            return receipt.hash
          },
          eth_chainId: async () => currentChain.id,
          eth_signTypedData: async ({ params }) => {
            const parsedMsg = JSON.parse(params[1])

            const types = {} as Record<string, any>
            for (const key in parsedMsg.types) {
              if (key !== 'EIP712Domain') {
                types[key] = parsedMsg.types[key]
              }
            }
            parsedMsg.types = types
            const args = [parsedMsg.domain, parsedMsg.types, parsedMsg.message]
            return await activeWallet._signTypedData(args[0], args[1], args[2])
          },
          eth_signTypedData_v4: async ({ params }) => {
            const parsedMsg = JSON.parse(params[1])

            const types = {} as Record<string, any>
            for (const key in parsedMsg.types) {
              if (key !== 'EIP712Domain') {
                types[key] = parsedMsg.types[key]
              }
            }
            parsedMsg.types = types
            const args = [parsedMsg.domain, parsedMsg.types, parsedMsg.message]
            return await activeWallet._signTypedData(args[0], args[1], args[2])
          },
          eth_signTransaction: async ({ params }) => {
            return '0x'
          },
          eth_sign: async ({ params }) => {
            const msg = params[0]
            return await activeWallet.signMessage(msg)
          },
          personal_sign: async ({ params }) => {
            const msg = params[0]
            return await activeWallet.signMessage(msg)
          },
        })

        eip1193Provider.on = eventEmitter.on.bind(eventEmitter)

        return {
          provider: eip1193Provider,
        }
      },
      platforms: ['desktop', 'mobile'],
    }
  }
}

export default hdWalletModule
