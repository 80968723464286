import { useState, useMemo, type ReactElement } from 'react'
import {
  Box,
  Divider,
  Grid,
  styled,
  Typography,
  Stack,
  Tooltip,
  Tabs,
  Tab,
  Button,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
} from '@mui/material'
import CardContainer from '@/components/dashboard/CardContainer'
import TokenIcon from '@/components/common/TokenIcon'
import useBalances from '@/hooks/useBalances'
import { formatCurrency } from '@/utils/formatNumber'
import { useAppSelector } from '@/store'
import { selectCurrency } from '@/store/settingsSlice'
import { formatVisualAmount } from '@/utils/formatters'
import useDesktop from '@/hooks/useDesktop'
import AddIcon from '@mui/icons-material/Add'
import QrCodeButton from '@/components/sidebar/QrCodeButton'

const Btn = styled(Button)(`
    height: 32px;
    padding: 10px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 160%;
    border-radius: 4px;
    border: 1px solid var(--Emerald-600, #22C55E);
`)

const StyledTab = styled(Tab)({
  color: 'var(--Ebony-Clay-700, #485581)',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '160%',
  textTransform: 'none',
  '&.Mui-selected': {
    color: 'var(--Ebony-Clay-300, #D2D7E5)',
  },
})

const StyledList = styled(List)({
  padding: 0,
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'var(--Supporting-Dark-BG-700, #272A3E)',
  },
})

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      flex={1}
      alignItems="center"
      justifyContent="center"
      p={0}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

interface TokenItem {
  logoURI: string
  name: string
  symbol: string
  amount: string
  amountInUSD: string
}

const formatName = (name: string, maxLength: number): string => {
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name
}

const Assets = ({ showTitle = true, isModal = false }: { showTitle?: boolean; isModal?: boolean }): ReactElement => {
  const isDesktop = useDesktop()

  const [value, setValue] = useState(0)
  const { balances, loading: balancesLoading } = useBalances()
  const currency = useAppSelector(selectCurrency)

  const isNoToken = balances.items.length === 0

  const numTokens = useMemo(() => balances.items.filter((token) => token.balance !== '0').length, [balances])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const formatCryptoCurrency = (value: number, digits: number): string => {
    return value.toLocaleString(undefined, { maximumFractionDigits: digits })
  }

  const tokenList: TokenItem[] = balances.items.map((item) => {
    return {
      logoURI: item.tokenInfo.logoUri,
      name: item.tokenInfo.name,
      symbol: item.tokenInfo.symbol,
      // amount: formatCryptoCurrency(Number(item.balance) / 10 ** item.tokenInfo.decimals, 6),
      amount: formatVisualAmount(item.balance, item.tokenInfo.decimals, 6),
      // amount: item.balance,
      amountInUSD: formatCurrency(parseFloat(item.fiatBalance), currency),
    }
  })

  return (
    <CardContainer
      title={showTitle ? 'Assets' : ''}
      // headerBtn={
      //   !isNoToken ? (
      //     <Btn
      //       startIcon={
      //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      //           <g clipPath="url(#clip0_2682_5116)">
      //             <path
      //               d="M12.6666 8.66668H8.66659V12.6667H7.33325V8.66668H3.33325V7.33334H7.33325V3.33334H8.66659V7.33334H12.6666V8.66668Z"
      //               fill="#22C55E"
      //             />
      //           </g>
      //           <defs>
      //             <clipPath id="clip0_2682_5116">
      //               <rect width="16" height="16" fill="white" />
      //             </clipPath>
      //           </defs>
      //         </svg>
      //       }
      //     >
      //       Add funds
      //     </Btn>
      //   ) : (
      //     <></>
      //   )
      // }
    >
      <Box flex={1} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#5D5DFF',
              },
            }}
          >
            <StyledTab label={`Tokens (${numTokens})`} {...a11yProps(0)} />
            <StyledTab label="NFTs (0)" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {balancesLoading && (
            <Grid container p="16px" pt="8px" spacing={1} sx={{ borderBottom: '1px solid #2F354B' }}>
              <Grid item container xs="auto" alignContent="center">
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs>
                <Skeleton width={200} />
                <Skeleton width={100} />
              </Grid>
            </Grid>
          )}
          {!balancesLoading && (isNoToken ? <NoToken /> : <ListTokens tokenList={tokenList} isModal={isModal} />)}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <NoToken />
        </CustomTabPanel>
      </Box>
    </CardContainer>
  )
}

const ListTokens = ({ tokenList, isModal }: { tokenList: TokenItem[]; isModal: boolean }): ReactElement => {
  return (
    <>
      <StyledList disablePadding style={{ maxHeight: '389px', overflow: 'auto' }}>
        {tokenList.map((token, index) => (
          <ListItemToken
            key={index}
            logoURI={token.logoURI}
            name={token.name}
            symbol={token.symbol}
            amount={token.amount}
            amountInUSD={token.amountInUSD}
            isModal={isModal}
          />
        ))}
      </StyledList>
    </>
  )
}

const ListItemToken = ({
  logoURI,
  name,
  symbol,
  amount,
  amountInUSD,
  isModal,
}: {
  logoURI: string
  name: string
  symbol: string
  amount: string
  amountInUSD: string
  isModal: boolean
}): ReactElement => {
  const isDesktop = useDesktop()
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton sx={isModal && !isDesktop ? { paddingX: '0px' } : {}}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ paddingY: '8px' }}>
            <Grid item xs={6} flex={1}>
              <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                <Grid item>
                  <TokenIcon logoUri={logoURI} size={44} tokenSymbol={symbol} showBackground />
                </Grid>
                <Grid item>
                  <Tooltip title={name} placement="top" arrow>
                    <Typography
                      sx={{
                        color: 'var(--Ebony-Clay-300, #D2D7E5)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontStyle: 'normal',
                        lineHeight: '160%',
                      }}
                      variant="body1"
                      component="h2"
                    >
                      {formatName(name, 10)}
                    </Typography>
                  </Tooltip>
                  <Typography
                    sx={{
                      color: 'var(--Ebony-Clay-500, #7C8AB4)',
                      fontSize: '14px',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      lineHeight: '160%',
                    }}
                    variant="body1"
                    component="h2"
                  >
                    {formatName(symbol, 10)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Stack>
                <Typography
                  sx={{
                    color: 'var(--Ebony-Clay-300, #D2D7E5)',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    lineHeight: '160%',
                    textAlign: 'right',
                  }}
                  variant="body1"
                  component="h2"
                >
                  {amount}{' '}
                  <Tooltip title={symbol} placement="top" arrow>
                    <span
                      style={{
                        color: 'var(--Ebony-Clay-500, #7C8AB4)',
                        fontWeight: '400',
                      }}
                    >
                      {formatName(symbol, 6)}
                    </span>
                  </Tooltip>
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Ebony-Clay-500, #7C8AB4)',
                    fontSize: '14px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    lineHeight: '160%',
                    textAlign: 'right',
                  }}
                  variant="body1"
                  component="h2"
                >
                  ~ ${amountInUSD}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <Divider flexItem />
    </>
  )
}

const NoToken = (): ReactElement => {
  const isDesktop = useDesktop()

  return (
    <>
      <Grid
        container
        px="16px"
        my={isDesktop ? '48px' : '16px'}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          <Typography
            sx={{
              color: 'var(--Ebony-Clay-600, #5B6B9C)',
              fontSize: '16px',
              fontWeight: '400',
              fontStyle: 'normal',
              lineHeight: '160%',
            }}
            variant="body1"
            component="h2"
          >
            No tokens yet!
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          <Typography
            sx={{
              color: 'var(--Ebony-Clay-300, #D2D7E5)',
              fontSize: '16px',
              fontWeight: 'bold',
              fontStyle: 'normal',
              lineHeight: '160%',
            }}
            variant="body1"
            component="h2"
          >
            Let&apos;s Fill Your Wallet: Add Funds Now!
          </Typography>
        </Grid>
        <Grid item container xs={12} sm="auto" alignItems="center" justifyContent="center">
          <QrCodeButton>
            <Btn
              startIcon={<AddIcon />}
              sx={isDesktop ? { minWidth: '150px', height: '38px' } : { width: '100%', height: '42px' }}
            >
              Add funds
            </Btn>
          </QrCodeButton>
        </Grid>
      </Grid>
    </>
  )
}

export default Assets
