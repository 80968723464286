import ChainIndicator from '@/components/common/ChainIndicator'
import { type ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import Link from 'next/link'
import type { SelectChangeEvent } from '@mui/material'
import { ListSubheader, MenuItem, Select, Skeleton, Tooltip } from '@mui/material'
import partition from 'lodash/partition'
import ExpandMoreIcon from '@/public/images/icons/arrow-drop-down.svg'
import useChains from '@/hooks/useChains'
import { useRouter } from 'next/router'
import css from './styles.module.css'
import { useChainId } from '@/hooks/useChainId'
import { type ReactElement, forwardRef, useMemo } from 'react'
import { useCallback } from 'react'
import { AppRoutes } from '@/config/routes'
import { trackEvent, OVERVIEW_EVENTS } from '@/services/analytics'
import useWallet from '@/hooks/wallets/useWallet'
import { isSocialWalletEnabled } from '@/hooks/wallets/wallets'
import { isSocialLoginWallet } from '@/services/mpc/SocialLoginModule'
import useDesktop from '@/hooks/useDesktop'
import { isTestnet } from '@/utils/networks'
import CheckIcon from '@mui/icons-material/Check'

const keepPathRoutes = [AppRoutes.welcome.index, AppRoutes.newSafe.create, AppRoutes.newSafe.load]

const MenuWithTooltip = forwardRef<HTMLUListElement>(function MenuWithTooltip(props: any, ref) {
  return (
    <Tooltip title="More network support coming soon" arrow placement="left">
      <ul ref={ref} {...props}>
        {props.children}
      </ul>
    </Tooltip>
  )
})

const NetworkSelector = (props: { onChainSelect?: () => void }): ReactElement => {
  const wallet = useWallet()
  const { configs } = useChains()
  const chainId = useChainId()
  const router = useRouter()
  const isDesktop = useDesktop()

  const [testNets, prodNets] = useMemo(() => partition(configs, (config) => isTestnet(config.shortName)), [configs])

  const getNetworkLink = useCallback(
    (shortName: string) => {
      const shouldKeepPath = keepPathRoutes.includes(router.pathname)

      const route = {
        pathname: shouldKeepPath ? router.pathname : '/',
        query: {
          chain: shortName,
        } as {
          chain: string
          walletViewRedirectURL?: string
        },
      }

      if (router.query?.walletViewRedirectURL) {
        route.query.walletViewRedirectURL = router.query?.walletViewRedirectURL.toString()
      }

      return route
    },
    [router],
  )

  const onChange = (event: SelectChangeEvent) => {
    event.preventDefault() // Prevent the link click

    const newChainId = event.target.value
    const shortName = configs.find((item) => item.chainId === newChainId)?.shortName

    if (shortName) {
      trackEvent({ ...OVERVIEW_EVENTS.SWITCH_NETWORK, label: newChainId })
      router.push(getNetworkLink(shortName))
    }
  }

  const isSocialLogin = isSocialLoginWallet(wallet?.label)

  const renderMenuItem = useCallback(
    (value: string, chain: ChainInfo) => {
      return (
        <MenuItem
          key={value}
          value={value}
          className={css.menuItem}
          disabled={isSocialLogin && !isSocialWalletEnabled(chain)}
          sx={{
            '&.Mui-selected:hover': {
              backgroundColor: 'var(--Ebony-Clay-800, #3B4569)',
            },
            '&:hover': {
              backgroundColor: 'var(--Ebony-Clay-800, #3B4569)',
            },
          }}
        >
          <Link href={getNetworkLink(chain.shortName)} onClick={props.onChainSelect} className={css.item}>
            <ChainIndicator chainId={chain.chainId} inline />{' '}
            {value === chainId && (
              <CheckIcon fontSize="small" sx={{ color: 'var(--Emerald-600, #22C55E)', marginLeft: '8px' }} />
            )}
          </Link>
        </MenuItem>
      )
    },
    [getNetworkLink, isSocialLogin, props.onChainSelect, chainId],
  )

  return configs.length ? (
    <Select
      value={chainId}
      onChange={onChange}
      size="small"
      className={css.select}
      variant="standard"
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        transitionDuration: 0,
        MenuListProps: { component: isSocialLogin ? MenuWithTooltip : undefined },
        sx: {
          '& .MuiPaper-root': {
            overflow: 'auto',
          },
        },
      }}
      sx={{
        '& .MuiSelect-select': {
          py: 0,
        },
      }}
      inputProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: 'var(--Supporting-Dark-BG-800, #1F2232)',
              borderRadius: '4px',
              width: '200px',
              overflow: 'auto',
              maxHeight: '500px',
            },
          },
        },
      }}
      renderValue={(value) => <ChainIndicator chainId={value} inline showFullName={true} />}
    >
      {prodNets.length > 0 && (
        <ListSubheader className={css.listSubHeader} sx={{ marginBottom: '10px' }}>
          Mainnets
        </ListSubheader>
      )}

      {prodNets.map((chain) => renderMenuItem(chain.chainId, chain))}

      {testNets.length > 0 && (
        <ListSubheader className={css.listSubHeader} sx={{ marginTop: '10px' }}>
          Testnets
        </ListSubheader>
      )}

      {testNets.map((chain) => renderMenuItem(chain.chainId, chain))}
    </Select>
  ) : (
    <Skeleton width={94} height={31} sx={{ mx: 2 }} />
  )
}

export default NetworkSelector
