import { type SafeBalanceResponse, type TokenInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { safeFormatUnits } from '@/utils/formatters'
import { formatAmount } from '@/utils/formatNumber'
import { getWeb3ReadOnly } from '@/hooks/wallets/web3'
import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { Contract, utils } from 'ethers'
import { getInheritManager, getContractNetwork } from '@/utils/contracts'
import { getERC20TokensInfoOnChain } from '@/utils/tokens'
import { camelCaseToSpaces } from '@/utils/formatters'
import { isTestnet } from '@/utils/networks'
import { formatDate } from '@/utils/date'
import _ from 'lodash'

export const routeScanUrl = 'https://api.routescan.io/v2'
export const binanceUrl = 'https://api.binance.com/api/v3'
export const coingeckoUrl = 'https://api.geckoterminal.com/api/v2'

export const logoUrl = 'https://safe-transaction-assets.safe.global/tokens/logos'

const geckoNetwork: { [key: string]: string } = {
  sep: 'sepolia-testnet',
}

const NATIVE_ADDRESS = '0x0000000000000000000000000000000000000000'

export type ItemBalance = {
  tokenInfo: TokenInfo
  balance: string
  fiatBalance: string
  fiatConversion: string
}

export const getPriceBySymbol = async (symbol: string): Promise<string> => {
  const response = await fetch(`${binanceUrl}/ticker/price?symbol=${symbol}USDT`)
  if (!response.ok) throw 'Error: fetching price'

  const json = await response.json()
  return json.price
}

export const getTokenBalances = async (
  address: string,
  chainInfo: ChainInfo,
  limit: number = 50,
): Promise<SafeBalanceResponse> => {
  try {
    let result: SafeBalanceResponse = {
      fiatTotal: '0',
      items: [],
    }
    const nativeBalance = await getNativeBalance(address, chainInfo)
    result.items.push(nativeBalance)

    try {
      const erc20Balances = await getERC20Balances(address, chainInfo)
      result.items = result.items.concat(erc20Balances)
    } catch (error) {
      console.error('Error fetching ERC20 balances', error)
    }

    result.fiatTotal = result.items
      .reduce((acc, item) => {
        return acc + parseFloat(item.fiatBalance)
      }, 0)
      .toString()
    return result
  } catch (error) {
    throw error
  }
}

export const getERC20Balances = async (
  address: string,
  chainInfo: ChainInfo,
  limit: number = 50,
): Promise<ItemBalance[]> => {
  const network = isTestnet(chainInfo.shortName) ? 'testnet' : 'mainnet'
  const chainId = chainInfo.chainId

  const response = await fetch(
    `${routeScanUrl}/network/${network}/evm/${chainId}/address/${address}/erc20-holdings?limit=${limit}`,
  )
  if (!response.ok) throw 'Error: fetching balances'

  const json = await response.json()
  const items = json.items.map((item: any) => {
    return {
      tokenInfo: {
        type: 'ERC20',
        address: item.tokenAddress,
        decimals: item.tokenDecimals,
        symbol: item.tokenSymbol,
        name: item.tokenName,
        logoUri: `${logoUrl}/${item.tokenAddress}.png`,
      },
      balance: item.tokenQuantity,
      fiatBalance: item.tokenValueInUsd,
      fiatConversion: '',
    }
  })
  return items as ItemBalance[]
}

export const getNativeBalance = async (address: string, chainInfo: ChainInfo): Promise<ItemBalance> => {
  try {
    const web3 = getWeb3ReadOnly()
    if (!web3) {
      throw new Error('Web3 provider not found')
    }
    const nativeCurrency = chainInfo.nativeCurrency
    const ethBalance = await web3.getBalance(address)

    const price = parseFloat(await getPriceBySymbol(nativeCurrency.symbol)) || 0
    const balance = parseFloat(safeFormatUnits(ethBalance.toString(), 18)) || 0
    const fiatBalance = formatAmount(price * balance)

    const native = {
      tokenInfo: {
        type: 'NATIVE_TOKEN',
        address: NATIVE_ADDRESS,
        ...nativeCurrency,
      },
      balance: ethBalance.toString(),
      fiatBalance,
      fiatConversion: '0',
    }
    return native as ItemBalance
  } catch (error) {
    throw error
  }
}

export const getNativeTokenInfo = async (chainInfo: ChainInfo): Promise<TokenInfoV2> => {
  const nativeCurrency = chainInfo.nativeCurrency
  const price = await getPriceBySymbol(nativeCurrency.symbol)

  const tokenInfo = {
    type: 'NATIVE_TOKEN',
    address: NATIVE_ADDRESS,
    ...nativeCurrency,
    priceUsd: price,
  }
  return tokenInfo as TokenInfoV2
}

export type TokenTxItemProps = {
  txType: 'Sent' | 'Received'
  txHash: string
  from: string
  to: string
  tokenAddress: string
  tokenInfo: {
    name: string
    symbol: string
    decimals: number
    logoUri: string
  }
  amount: string
  uiAmount: string
  createdAt: string
  timestamp: number
}

// export const getERC20Txns = async (
//   address: string,
//   chainInfo: ChainInfo,
//   next?: string,
//   limit: number = 25,
// ): Promise<TokenTxItemProps[]> => {
//   // const network = isTestnet(chainInfo.shortName) ? 'testnet' : 'mainnet'
//   // const chainId = chainInfo.chainId

//   // const response = await fetch(
//   //   `${routeScanUrl}/network/${network}/evm/${chainId}/address/${address}/erc20-transfers?limit=${limit}${
//   //     next ? `&next=${next}` : ''
//   //   }`,
//   // )

//   // if (!response.ok) throw 'Error: fetching balances'

//   // const json = await response.json()
//   // await getNativeTxns(address, chainInfo)
//   const json = fakeERC20Txns as any
//   console.log('getERC20Txns json ===============: ', json)

//   // const json = fakeERC20Txns as any
//   if (json.items.length > 0) {
//     const tokenAddresses = json.items
//       .filter((item: any) => item.tokenAddress !== address)
//       .map((item: any) => item.tokenAddress) as string[]

//     let tokenInfos: TokenInfoV2[] = []
//     const nativeTokenInfo = await getNativeTokenInfo(chainInfo)
//     tokenInfos.push(nativeTokenInfo)

//     if (tokenAddresses.length > 0) {
//       const erc20Infos = await getTokenInfoFromGecko(chainInfo, tokenAddresses)
//       tokenInfos = tokenInfos.concat(erc20Infos)
//     }
//     console.log('tokenInfos', tokenInfos)

//     const items = json.items.map((item: any) => {
//       const token = address === item.tokenAddress ? NATIVE_ADDRESS : (item.tokenAddress as string)
//       console.log('token ===== : ', token)
//       const tokenInfo = tokenInfos.find((info) => info.address === token)
//       if (!tokenInfo) throw 'Error: token info not found'
//       const uiAmount = safeFormatUnits(item.amount, tokenInfo.decimals)
//       const txType = address === item.from ? 'Sent' : 'Received'

//       return {
//         txType,
//         from: item.from,
//         to: item.to,
//         tokenAddress: item.tokenAddress,
//         tokenInfo: {
//           name: tokenInfo.name,
//           symbol: tokenInfo.symbol,
//           decimals: tokenInfo.decimals,
//           logoUri: tokenInfo.logoUri,
//         },
//         amount: item.amount,
//         uiAmount,
//         createdAt: item.createdAt,
//         timestamp: item.timestamp,
//       }
//     }) as TokenTxItemProps[]
//     console.log('getERC20Txns: ', items)
//     return items
//   }
//   return [] as TokenTxItemProps[]
// }

export type TokenInfoV2 = {
  priceUsd: string
} & TokenInfo

// exclude native token
export const getTokenInfoFromGecko = async (chainInfo: ChainInfo, tokens: string[]): Promise<TokenInfoV2[]> => {
  const network = geckoNetwork[chainInfo.shortName]

  if (!network || network === '') throw 'Error: gecko network not found'

  const tokenList = tokens.join(',')
  const response = await fetch(`${coingeckoUrl}/networks/${network}/tokens/multi/${tokenList}`)
  if (!response.ok) throw 'Error: fetching token info'
  const json = await response.json()

  // const json = fakeTokenInfoResponse as any
  const items = json.data.map((item: any) => {
    return {
      type: 'ERC20',
      address: item.attributes.address,
      decimals: item.attributes.decimals,
      symbol: item.attributes.symbol,
      name: item.attributes.name,
      logoUri: item.attributes.image_url,
      priceUsd: item.attributes.price_usd,
    }
  })
  return items as TokenInfoV2[]
}

export type TokenHistoryGrouped = {
  label: string
  items: TokenTxItemProps[]
}

export type TokenTransfersTxns = {
  items: TokenHistoryGrouped[]
  page: number
  offset: number
  updatedAt: number
}

export const getNativeTxns = async (
  address: string,
  chainInfo: ChainInfo,
  page: number = 1,
  offset: number = 1000,
): Promise<TokenTransfersTxns> => {
  const network = isTestnet(chainInfo.shortName) ? 'testnet' : 'mainnet'
  const chainId = chainInfo.chainId
  const topic0 = '0xce8688f853ffa65c042b72302433c25d7a230c322caba0901587534b6551091d' // NativeTransfer(address,address,uint256)

  const response = await fetch(
    `${routeScanUrl}/network/${network}/evm/${chainId}/etherscan/api?module=logs&action=getLogs&fromBlock=0&toBlock=99999999&address=${address}&topic0=${topic0}&page=${page}&offset=${offset}`,
  )
  if (!response.ok) throw 'Error: fetching native txns'

  const json = await response.json()

  if (json.status !== '1' || !json.result || json.result.length === 0) throw 'Error: fetching native txns'

  const abi = ['event NativeTransfer(address indexed src, address indexed dst, uint val)']
  const contract = new Contract(address, abi)

  const nativeTokenInfo = await getNativeTokenInfo(chainInfo)

  const items: TokenTxItemProps[] = json.result.map((log: any) => {
    const event = contract.interface.parseLog({
      topics: log.topics,
      data: log.data,
    })
    const { src, dst, val } = event.args
    return {
      txType: address === src ? 'Sent' : 'Received',
      txHash: log.transactionHash,
      from: src,
      to: dst,
      tokenAddress: NATIVE_ADDRESS,
      tokenInfo: {
        name: nativeTokenInfo.name,
        symbol: nativeTokenInfo.symbol,
        decimals: nativeTokenInfo.decimals,
        logoUri: nativeTokenInfo.logoUri,
      },
      amount: val,
      uiAmount: safeFormatUnits(val, nativeTokenInfo.decimals),
      createdAt: parseInt(log.timeStamp, 16),
      timestamp: parseInt(log.timeStamp, 16),
    }
  })
  if (items.length === 0) return { items: [], page, offset, updatedAt: Date.now() }

  const sorted = items.reverse()
  const grouped = _.groupBy(sorted, ({ timestamp }) => Math.floor(timestamp / 86400) * 86400)
  const groupedNativeTransfer: TokenHistoryGrouped[] = Object.keys(grouped)
    .sort((a: any, b: any) => Number(b) - Number(a))
    .map((key) => {
      return {
        label: formatDate(Number(key) * 1000),
        items: grouped[key],
      }
    })

  return {
    items: groupedNativeTransfer,
    page,
    offset,
    updatedAt: Date.now(),
  }
}

export const getERC20Txns = async (
  address: string,
  chainInfo: ChainInfo,
  page: number = 1,
  offset: number = 1000,
): Promise<TokenTransfersTxns> => {
  const network = isTestnet(chainInfo.shortName) ? 'testnet' : 'mainnet'
  const chainId = chainInfo.chainId
  const topic0 = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' // Transfer(address,address,uint256)
  const topic12 = utils.hexZeroPad(address.toLowerCase(), 32)
  const response = await fetch(
    `${routeScanUrl}/network/${network}/evm/${chainId}/etherscan/api?module=logs&action=getLogs&fromBlock=0&toBlock=99999999&topic1_2_opr=or&topic1=${topic12}&topic2=${topic12}&page=${page}&offset=${offset}`,
  )
  if (!response.ok) throw 'Error: fetching native txns'

  const json = await response.json()

  if (json.status !== '1' || !json.result || json.result.length === 0) throw 'Error: fetching native txns'

  const abi = ['event Transfer(address indexed src, address indexed dst, uint val)']
  const contract = new Contract(address, abi)

  const onlyERC20Transfer = json.result.filter((log: any) => log.topics[0] === topic0)

  if (onlyERC20Transfer.length === 0) return { items: [], page, offset, updatedAt: Date.now() }

  const tokenAddresses = Object.keys(_.groupBy(onlyERC20Transfer, 'address'))

  let tokenInfos: TokenInfoV2[] = []

  try {
    tokenInfos = await getTokenInfoFromGecko(chainInfo, tokenAddresses)
  } catch (error) {
    tokenInfos = await getERC20TokensInfoOnChain(chainId, tokenAddresses)
  }

  const items: TokenTxItemProps[] = await Promise.all(
    onlyERC20Transfer.map(async (log: any): Promise<TokenTxItemProps> => {
      const event = contract.interface.parseLog({
        topics: log.topics,
        data: log.data,
      })

      const { src, dst, val } = event.args
      let tokenInfo = tokenInfos.find((info) => info.address === log.address)
      if (!tokenInfo) {
        const __tokenInfo = await getERC20TokensInfoOnChain(chainId, [log.address])
        tokenInfo = __tokenInfo[0]
      }

      return {
        txType: address === src ? 'Sent' : 'Received',
        txHash: log.transactionHash,
        from: src,
        to: dst,
        tokenAddress: log.address,
        tokenInfo: {
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
          decimals: tokenInfo.decimals,
          logoUri: tokenInfo.logoUri,
        },
        amount: val,
        uiAmount: safeFormatUnits(val, tokenInfo.decimals),
        createdAt: log.timeStamp,
        timestamp: parseInt(log.timeStamp, 16),
      }
    }),
  )

  if (items.length === 0) return { items: [], page, offset, updatedAt: Date.now() }

  const sorted = items.reverse()
  const grouped = _.groupBy(sorted, ({ timestamp }) => Math.floor(timestamp / 86400) * 86400)
  const groupedNativeTransfer: TokenHistoryGrouped[] = Object.keys(grouped)
    .sort((a: any, b: any) => Number(b) - Number(a))
    .map((key) => {
      return {
        label: formatDate(Number(key) * 1000),
        items: grouped[key],
      }
    })

  return {
    items: groupedNativeTransfer,
    page,
    offset,
    updatedAt: Date.now(),
  }
}

export type SystemTxItemProps = {
  action: string
  wallet: string
  event: utils.LogDescription
  txHash: string
  createdAt: number
  timestamp: number
}

export type SystemHistoryGrouped = {
  label: string
  items: SystemTxItemProps[]
}

export type SystemTxns = {
  items: SystemHistoryGrouped[]
  page: number
  offset: number
  updatedAt: number
}

export const getSystemTxns = async (
  address: string,
  chainInfo: ChainInfo,
  page: number = 1,
  offset: number = 1000,
): Promise<SystemTxns> => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainInfo.chainId)
  const inheritManager = await getInheritManager(chainInfo.chainId)

  const topic1 = utils.hexZeroPad(address.toLowerCase(), 32)

  const network = isTestnet(chainInfo.shortName) ? 'testnet' : 'mainnet'
  const chainId = chainInfo.chainId

  const response = await fetch(
    `${routeScanUrl}/network/${network}/evm/${chainId}/etherscan/api?module=logs&action=getLogs&fromBlock=0&toBlock=99999999&address=${inheritWalletManagerAddress}&topic1=${topic1}&page=${page}&offset=${offset}`,
  )
  if (!response.ok) throw 'Error: fetching system txns'
  const json = await response.json()

  if (json.status !== '1' || !json.result || json.result.length === 0) throw 'Error: fetching system txns'

  const items: SystemTxItemProps[] = json.result.map((log: any) => {
    const event = inheritManager.interface.parseLog({
      topics: log.topics,
      data: log.data,
    })

    const action = camelCaseToSpaces(event.name)

    return {
      action,
      wallet: address,
      event,
      txHash: log.transactionHash,
      createdAt: parseInt(log.timeStamp, 16),
      timestamp: parseInt(log.timeStamp, 16),
    }
  })

  if (items.length === 0) return { items: [], page, offset, updatedAt: Date.now() }

  const sorted = items.reverse()

  const grouped = _.groupBy(sorted, ({ timestamp }) => Math.floor(timestamp / 86400) * 86400)
  const groupedSystemTransfer: SystemHistoryGrouped[] = Object.keys(grouped)
    .sort((a: any, b: any) => Number(b) - Number(a))
    .map((key) => {
      return {
        label: formatDate(Number(key) * 1000),
        items: grouped[key],
      }
    })

  return {
    items: groupedSystemTransfer,
    page,
    offset,
    updatedAt: Date.now(),
  }
}
