import { useEffect } from 'react'
import { type SafeInfo } from '@safe-global/safe-gateway-typescript-sdk'
import useAsync, { type AsyncResult } from '../useAsync'
import useSafeAddress from '../useSafeAddress'
import { useChainId } from '../useChainId'
import useIntervalCounter from '../useIntervalCounter'
import useSafeInfo from '../useSafeInfo'
import { Errors, logError } from '@/services/exceptions'
import { POLLING_INTERVAL } from '@/config/constants'
// import { useCurrentChain } from '@/hooks/useChains'
import { getInheritWalletInfo } from '@/utils/api'
import { getWeb3ReadOnly } from '@/hooks/wallets/web3'
import useWallet from '../wallets/useWallet'

const getInheritWallet = async (chainId: string, owner: string, inheritWallet: string) => {
  let web3 = getWeb3ReadOnly()

  while (!web3) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    web3 = getWeb3ReadOnly()
  }
  return getInheritWalletInfo(chainId, owner, inheritWallet)
}

export const useLoadSafeInfo = (): AsyncResult<SafeInfo> => {
  const address = useSafeAddress()
  const chainId = useChainId()
  const wallet = useWallet()
  const [pollCount, resetPolling] = useIntervalCounter(POLLING_INTERVAL)
  const { safe } = useSafeInfo()
  const isStoredSafeValid = safe.chainId === chainId && safe.address.value === address
  // console.log('useLoadSafeInfo')
  const [data, error, loading] = useAsync<SafeInfo>(() => {
    if (!chainId || !address || !wallet) return

    // return getSafeInfo(chainId, address)
    return getInheritWallet(chainId, wallet.address, address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, address, pollCount, wallet])

  // Reset the counter when safe address/chainId changes
  useEffect(() => {
    resetPolling()
  }, [resetPolling, address, chainId])

  // Log errors
  useEffect(() => {
    if (error) {
      logError(Errors._600, error.message)
    }
  }, [error])

  return [
    // Return stored SafeInfo between polls
    data ?? (isStoredSafeValid ? safe : data),
    error,
    loading,
  ]
}

export default useLoadSafeInfo
