import { type ReactElement, type ReactNode, useState, Suspense } from 'react'
import dynamic from 'next/dynamic'
import { Box } from '@mui/material'

const ReceiveModal = dynamic(() => import('./ReceiveModal'))
const QrModal = dynamic(() => import('./QrModal'))

const QrCodeButton = ({
  children,
  display = 'grid',
  onlyQR = true,
}: {
  children: ReactNode
  display?: string
  onlyQR?: boolean
}): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <Box display={display} sx={{ width: '100%' }} onClick={() => setModalOpen(true)}>
        {children}
      </Box>

      {modalOpen && (
        <Suspense>
          {onlyQR && <QrModal onClose={() => setModalOpen(false)} />}
          {!onlyQR && <ReceiveModal onClose={() => setModalOpen(false)} />}
        </Suspense>
      )}
    </>
  )
}

export default QrCodeButton
