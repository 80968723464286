import { useEffect, useState } from 'react'
import useDesktop from './useDesktop'
import { useRouter } from 'next/router'

const useMenuFoot = () => {
  const [isMenuFootOpen, setIsMenuFootOpen] = useState(false)
  const isDesktop = useDesktop()
  const router = useRouter()

  useEffect(() => {
    if (isDesktop) {
      setIsMenuFootOpen(false)
    } else {
      if (router.route === '/apps/open' || router.route === '/welcome') {
        setIsMenuFootOpen(false)
      } else {
        setIsMenuFootOpen(true)
      }
    }
  }, [router, isDesktop])

  return isMenuFootOpen
}

export default useMenuFoot
