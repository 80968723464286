import { ethers } from 'ethers'
import { getLastActivity } from '@/utils/inherit-manager'
import type { SafeBalanceResponse, TokenInfo, ChainInfo, SafeInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { TokenType } from '@safe-global/safe-gateway-typescript-sdk'
import { safeFormatUnits } from '@/utils/formatters'
import { formatAmount } from '@/utils/formatNumber'
import { RuleType, type RuleInfo, type Inheritor } from '@/store/setUpInheritorsSlice'
import { type InheritanceInfoState } from '@/store/inheritanceInfoSlice'
import { getInheritWalletOnChain } from '@/utils/inherit-manager'
import { formatDate, formatDateTime, getPeriodValue, MONTH_IN_SECONDS, YEAR_IN_SECONDS } from '@/utils/date'
import { type TokenInfoV2 } from '@/utils/routescan'
// import { getERC20TokensInfoOnChain } from '@/utils/tokens'
import { getContractNetwork } from '@/utils/contracts'

import _ from 'lodash'
import { sameAddress } from './addresses'
import { getWeb3ReadOnly } from '@/hooks/wallets/web3'

export const apiUrl = 'https://api.inheritwallet.io/v1'
// export const apiUrl = 'http://localhost:3001/v1'

export const createInheritWallet = async (chainId: string, owner: string, inheritWallet: string) => {
  const response = await fetch(`${apiUrl}/evm/${chainId}/create-inherit-wallet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ owner, inheritWallet }),
  })

  if (!response.ok) throw 'Error: create inherit wallet'

  const json = await response.json()
  if (json.message !== 'ok') throw 'Error: create inherit wallet'

  return inheritWallet
}

export type ContractInfoResponse = {
  name: string
  abi: string
}

export const getContractInfo = async (chainId: string, address: string) => {
  const response = await fetch(`${apiUrl}/evm/${chainId}/address/${address}/contract-info`)
  if (!response.ok) throw 'Error: fetch contract info'

  const json = await response.json()
  if (json.message !== 'ok') throw 'Error: fetch contract info'

  const result = json.data as ContractInfoResponse
  return result
}

export type BalanceChangeResponse = {
  amount: string
  assetType: 'NATIVE' | 'ERC20' | 'ERC721' | 'ERC1155'
  changeType: string
  decimals: number
  from: string
  to: string
  logo: string
  name: string
  symbol: string
  rawAmount: string
  contractAddress: string
}
export type SimulateBalanceChangeResponse = {
  changes: BalanceChangeResponse[]
  gasUsed: string
}

export const simulateBalanceChange = async (
  chain: ChainInfo,
  address: string,
  to: string,
  value: string,
  data: string,
): Promise<SimulateBalanceChangeResponse> => {
  const _value = ethers.utils.hexValue(ethers.BigNumber.from(value).toHexString())
  const response = await fetch(`${apiUrl}/evm/${chain.chainId}/address/${address}/simulate-asset-changes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ to, value: _value, data }),
  })

  if (!response.ok) throw 'Error: simulate balance change'
  const json = await response.json()

  if (json.message !== 'ok') throw 'Error: simulate balance change'

  const result = json.data as SimulateBalanceChangeResponse

  const noLogoTokens = result.changes.filter((change) => !change.logo || change.logo === '')
  const tokens = noLogoTokens.map((change) => change.contractAddress)

  const uniqueTokens = [...new Set(tokens)]
  if (uniqueTokens.length > 0) {
    // get token info
    const tokenInfos = await getTokenInfo(chain.chainId, uniqueTokens)

    const newChanges = result.changes.map((change) => {
      const tokenInfo = tokenInfos.find((info) => sameAddress(info.address, change.contractAddress))
      if (tokenInfo) {
        return {
          ...change,
          logo: tokenInfo.logoUri,
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
        }
      }
      return change
    })

    result.changes = newChanges
  }

  return result
}

export const getTokenInfo = async (chainId: string, tokens: string[]): Promise<TokenInfoV2[]> => {
  const response = await fetch(`${apiUrl}/evm/${chainId}/token-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tokens }),
  })

  if (!response.ok) throw 'Error: fetch token info'
  const json = await response.json()
  const data = json.data.map((item: any) => {
    const { address, name, symbol, logo, decimals, usdPrice } = item
    return {
      type: TokenType.ERC20,
      address,
      name,
      symbol,
      logoUri: logo,
      decimals,
      priceUsd: usdPrice,
    }
  }) as TokenInfoV2[]

  // const noInfoTokens = tokens.filter((token) => !data.find((item) => sameAddress(item.address, token)))

  // console.log('noInfoTokens', noInfoTokens)
  // const tokenInfo = await getERC20TokensInfoOnChain(chainId, noInfoTokens)

  return data
}

export type BalanceAPIResponse = {
  balance: string
  tokenAddress: string
  tokenInfo: {
    name: string
    symbol: string
    logo: string
    decimals: number
    usdPrice: string
  }
}

export type ClaimedToken = {
  tokenAddress: string
  amount: string
}

export type InheritorAPIResponse = {
  address: string
  claimedTokens: ClaimedToken[]
  name: string
  email: string
  messages: string
  percent: number
  inheritWalletAddress: string
  inheritWallet: {
    account: {
      balances: BalanceAPIResponse[]
    }
    ruleType: number
    ruleValue: number
  }
}

export type InheritanceOfInheritor = {
  legator: string
  ruleInfo: RuleInfo
  balances: SafeBalanceResponse
  lastActivity: number
  inheritor: string
  percent: number
  name: string
  email: string
  messages: string
  claimedTokens: ClaimedToken[]
}

const getClaimableTokens = (
  balances: BalanceAPIResponse[],
  claimedTokens: ClaimedToken[],
  percent: number,
): BalanceAPIResponse[] => {
  const unclaimedTokens = balances.filter((balance) => {
    const claimed = claimedTokens.find((claimed) => claimed.tokenAddress === balance.tokenAddress)
    if (!claimed) return true
    return false
  })

  return unclaimedTokens
}

export const getInheritorInfo = async (chain: ChainInfo, address: string): Promise<InheritanceOfInheritor[]> => {
  const response = await fetch(`${apiUrl}/evm/${chain.chainId}/address/${address}/inheritor`)
  if (!response.ok) throw 'Error: fetch inheritor info'

  const json = await response.json()

  const result: InheritanceOfInheritor[] = []
  if (json.data.length > 0) {
    const inheritances = json.data as InheritorAPIResponse[]
    for (const inheritance of inheritances) {
      const { inheritWalletAddress, inheritWallet, percent, name, email, messages, claimedTokens } = inheritance
      const {
        ruleType,
        ruleValue,
        account: { balances },
      } = inheritWallet

      const lastActivity = await getLastActivity(inheritWalletAddress, chain.chainId)
      const isRuleMet = validateRule(ruleType, ruleValue, lastActivity)
      if (!isRuleMet) continue

      const claimable = getClaimableTokens(balances, claimedTokens, percent)
      if (claimable.length === 0) continue

      const ruleInfo = toRuleInfo(ruleType, ruleValue)

      const parsedBalances: SafeBalanceResponse = toSafeBalanceResponse(claimable)

      const data: InheritanceOfInheritor = {
        legator: inheritWalletAddress,
        ruleInfo,
        balances: parsedBalances,
        lastActivity,
        inheritor: address,
        percent,
        name,
        email,
        messages,
        claimedTokens,
      }
      result.push(data)
    }
  }
  return result
}

type InheritanceAPIResponse = {
  address: string
  name: string | null
  status: number
  ruleType: number
  ruleValue: number
  allocated: number
  lastActivity: number
  inheritors: {
    id: number
    address: string
    percent: number
    name?: string
    email?: string
    messages?: string
    claimedTokens: ClaimedToken[]
  }[]
}

export const getInheritance = async (chain: ChainInfo, address: string) => {
  const response = await fetch(`${apiUrl}/evm/${chain.chainId}/address/${address}/inheritance`)
  if (!response.ok) throw 'Error: fetch inheritance info'

  const json = await response.json()
  const data = json.data as InheritanceAPIResponse

  if (!data) throw 'Error: no inheritance data'
  let allocated = 0
  let lastActivity = 0
  try {
    const onChainInfo = await getInheritWalletOnChain(chain.chainId, address)
    allocated = onChainInfo.allocated
    lastActivity = onChainInfo.lastActivity
  } catch (e) {
    allocated = data.inheritors.reduce((acc, inheritor) => {
      return acc + inheritor.percent
    }, 0)
  }

  const inheritors: Inheritor[] = data.inheritors.map((inheritor, index) => {
    return {
      id: inheritor.id,
      address: inheritor.address,
      percentage: inheritor.percent / 100,
      name: inheritor.name || '',
      email: inheritor.email || '',
      messages: inheritor.messages || '',
      isEditing: false,
      isDone: false,
      index,
      claimed: inheritor.claimedTokens.length > 0,
    }
  })

  const claimed = data.inheritors.filter((inheritor) => inheritor.claimedTokens.length > 0)

  const result: InheritanceInfoState = {
    status: data.status,
    allocated,
    rule: toRuleInfo(data.ruleType, data.ruleValue),
    inheritors,
    updatedAt: Date.now(),
    claimed: claimed.length,
    lastActivity,
  }

  return result
}

type TokenBalanceAPIResponse = {
  chainId: string
  address: string
  type: string
  updatedAt: string
  balanceTag?: string
  balances: BalanceAPIResponse[]
}

export const getTokenBalances = async (chain: ChainInfo, address: string) => {
  const response = await fetch(`${apiUrl}/evm/${chain.chainId}/address/${address}/token-balance`)
  if (!response.ok) throw 'Error: fetch token balances'

  const json = await response.json()
  const data = json.data as TokenBalanceAPIResponse
  if (!data) throw 'Error: no token balances'

  const isNativeToken = data.balances.find((balance) => balance.tokenAddress === ethers.constants.AddressZero)
  const web3 = getWeb3ReadOnly()

  if (!isNativeToken && web3) {
    const nativeInfo = await getTokenInfo(chain.chainId, [ethers.constants.AddressZero])
    if (nativeInfo.length > 0) {
      const balance = await web3.getBalance(address)
      const nativeTokenBalance = {
        balance: balance.toString(),
        tokenAddress: ethers.constants.AddressZero,
        tokenInfo: {
          name: nativeInfo[0].name,
          symbol: nativeInfo[0].symbol,
          logo: nativeInfo[0].logoUri,
          decimals: nativeInfo[0].decimals,
          usdPrice: nativeInfo[0].priceUsd,
        },
      }
      data.balances.push(nativeTokenBalance)
    }
  }

  return toSafeBalanceResponse(data.balances)
}

type InheritWalletAPIResponse = {
  address: string
  owner: string[]
  name: string | null
  status: number
  ruleType: number
  ruleValue: number
  allocated: number
  lastActivity: number
  inheritors: []
}

export const getInheritWalletInfo = async (chainId: string, owner: string, inheritWallet: string) => {
  const response = await fetch(`${apiUrl}/evm/${chainId}/address/${inheritWallet}/inheritWallet`)
  if (!response.ok) throw 'Error: fetch inheritWallet info'

  const json = await response.json()
  const data = json.data as InheritWalletAPIResponse

  let threshold = 0
  let owners = [
    {
      value: owner,
      name: null,
      logoUri: null,
    },
  ]

  if (data) {
    threshold = data.status === 0 ? 0 : 1
    owners = data.owner.map((owner) => {
      return {
        value: owner,
        name: null,
        logoUri: null,
      }
    })
  } else {
    // TODO: fetch on-chain check if wallet exists
    const address = await createInheritWallet(chainId, owner, inheritWallet)
  }

  const contracts = getContractNetwork(chainId)

  const result: any = {
    address: {
      value: inheritWallet,
      name: null,
      logoUri: null,
    },
    chainId,
    nonce: 0,
    threshold,
    owners,
    implementation: {
      value: '',
      name: 'InheritWallet: 1.3.0',
      logoUri: null,
    },
    implementationVersionState: 'UP_TO_DATE',
    collectiblesTag: null,
    txQueuedTag: null,
    txHistoryTag: null,
    messagesTag: null,
    modules: null,
    fallbackHandler: {
      value: contracts.fallbackHandlerAddress,
      name: 'InheritWallet: CompatibilityFallbackHandler 1.3.0',
      logoUri: null,
    },
    guard: null,
    version: '1.3.0+L2',
  }
  return result as SafeInfo
}

type SystemHistoryDataAPIResponse = {
  id: number
  chainId: string
  wallet: string
  actionName?: string
  data?: any
  txHash: string
  createdAt: string
  blockInfo?: {
    blockNumber: number
    createdAt: string
  }
}

type SystemHistoryAPIResponse = {
  data: SystemHistoryDataAPIResponse[]
  cursor: number
}

export type SystemTxItemProps = {
  action: string
  wallet: string
  data: { key: string; value: any }[]
  txHash: string
  createdAt: number
  timestamp: number
}

export type SystemHistoryGrouped = {
  label: string
  items: SystemTxItemProps[]
}

export const getSystemHistory = async (chainId: string, address: string, take: number = 1000, cursor: number = 0) => {
  const response = await fetch(
    `${apiUrl}/evm/${chainId}/address/${address}/system-history?take=${take}&cursor=${cursor}`,
  )
  if (!response.ok) throw 'Error: fetch system-history'

  const json = await response.json()
  const data = json.data as SystemHistoryAPIResponse
  if (!data) throw 'Error: no system-history data'

  const grouped = toSystemHistoryGrouped(data.data)
  return grouped
}

export const toLabel: {
  [key: string]: string
} = {
  inheritWallet: 'New wallet',
  ownerAddress: 'Owner',
  ruleType: 'Rule type',
  onDate: 'On date',
  period: 'Period',
  inheritorId: 'Inheritor id',
  inheritor: 'Inheritor',
  oldInheritor: 'Old inheritor',
  newInheritor: 'New inheritor',
  percent: 'Percentage',
  oldPercent: 'Old percentage',
  newPercent: 'New percentage',
  recipient: 'Inheritor wallet',
  token: 'Token address',
  claimed: `Claimed amount`,
  tokenName: 'Token name',
  tokenSymbol: 'Token symbol',
  tokenDecimals: 'Token decimals',
  legator: 'Legator',
}

// const formatPeriodValue = (value: number) => {
//   if (value % year === 0) {
//     const _value = value / year
//     return `${_value} year${_value > 1 ? 's' : ''}`
//   }
//   if (value % month === 0) {
//     const _value = value / month
//     return `${_value} month${_value > 1 ? 's' : ''}`
//   }
//   const _value = value / day
//   return `${_value} day${_value > 1 ? 's' : ''}`
// }

const formatPercentValue = (key: string, value: number) => {
  if (key === 'percentage' || key === 'percent' || key === 'oldPercent' || key === 'newPercent') {
    return `${value / 100}%`
  }
  return value
}

export const parseData = (actionName: string, data: any) => {
  try {
    if (actionName === 'ChangedRule' || actionName === 'AddedRule') {
      const ruleType =
        data.ruleType === RuleType.Unknown
          ? 'Unknown'
          : data.ruleType === RuleType.AfterInactivityPeriod
          ? 'After inactivity period'
          : 'On specific date'

      const ruleValueLabel = data.ruleType === RuleType.OnSpecificDate ? 'On date' : 'Period'
      const ruleValue =
        data.ruleType === RuleType.OnSpecificDate
          ? formatDateTime(data.onDate * 1000 || 0)
          : getPeriodValue(data.period || 0)

      return [
        { key: 'Rule type', value: ruleType },
        { key: ruleValueLabel, value: ruleValue },
      ]
    }

    const parsedData = data
      ? Object.keys(data).map((key) => ({ key: toLabel[key] || key, value: formatPercentValue(key, data[key]) }))
      : []
    return parsedData
  } catch (e) {
    return []
  }
}

export const toSystemHistoryGrouped = (data: SystemHistoryDataAPIResponse[]) => {
  const items: SystemTxItemProps[] = data.map((item: SystemHistoryDataAPIResponse) => {
    const { wallet, data, actionName, txHash, createdAt } = item
    const action = actionName || ''
    const timestamp = Math.floor(new Date(createdAt).getTime() / 1000)
    const parsedData = parseData(actionName || '', data)
    return {
      action,
      wallet,
      data: parsedData,
      txHash,
      createdAt: timestamp,
      timestamp,
    }
  })

  const grouped = _.groupBy(items, ({ timestamp }) => Math.floor(timestamp / 86400) * 86400)
  const systemHistoryGrouped: SystemHistoryGrouped[] = Object.keys(grouped)
    .sort((a: any, b: any) => Number(b) - Number(a))
    .map((key) => {
      return { label: formatDate(Number(key) * 1000), items: grouped[key] }
    })

  return systemHistoryGrouped
}

export type TokenTxItemProps = {
  txType: 'Sent' | 'Received'
  txHash: string
  from: string
  to: string
  tokenAddress: string
  tokenInfo: {
    name: string
    symbol: string
    decimals: number
    logoUri: string
  }
  amount: string
  uiAmount: string
  createdAt: number
  timestamp: number
}

export type TokenHistoryGrouped = {
  label: string
  items: TokenTxItemProps[]
}

type TokenHistoryDataAPIResponse = {
  chainId: string
  blockHash: string
  blockInfo?: {
    blockNumber: number
    createdAt: string
  }
  tokenAddress: string
  fromAddress: string
  toAddress: string
  amount: string
  tokenInfo: {
    name: string
    symbol: string
    decimals: number
    logo?: string
    usdPrice?: string
  }
  txHash: string
  createdAt: string
}

type TokenHistoryAPIResponse = {
  data: TokenHistoryDataAPIResponse[]
  cursor: number
}

export const getTokenHistory = async (chainId: string, address: string, take: number = 1000, cursor: number = 0) => {
  const response = await fetch(
    `${apiUrl}/evm/${chainId}/address/${address}/token-history?take=${take}&cursor=${cursor}`,
  )
  if (!response.ok) throw 'Error: fetch token-history'
  const json = await response.json()
  const data = json.data as TokenHistoryAPIResponse

  if (!data) throw 'Error: no token-history data'

  return toTokenHistoryGrouped(address, data.data)
}

export const toTokenHistoryGrouped = (address: string, data: TokenHistoryDataAPIResponse[]) => {
  const items: TokenTxItemProps[] = data.map((item: TokenHistoryDataAPIResponse) => {
    const { fromAddress, toAddress, tokenAddress, tokenInfo, amount, createdAt, txHash } = item
    const txType = fromAddress === address ? 'Sent' : 'Received'
    // console.log('ui amount', formatVisualAmount(amount, tokenInfo.decimals, 6))
    return {
      txType,
      txHash,
      from: fromAddress,
      to: toAddress,
      tokenAddress,
      tokenInfo: {
        name: tokenInfo.name,
        symbol: tokenInfo.symbol,
        decimals: tokenInfo.decimals,
        logoUri: tokenInfo.logo || '',
      },
      amount,
      uiAmount: safeFormatUnits(amount, tokenInfo.decimals),
      createdAt: Math.floor(new Date(createdAt).getTime() / 1000),
      timestamp: Math.floor(new Date(createdAt).getTime() / 1000),
    }
  })

  const grouped = _.groupBy(items, ({ timestamp }) => Math.floor(timestamp / 86400) * 86400)
  const tokenHistoryGrouped: TokenHistoryGrouped[] = Object.keys(grouped)
    .sort((a: any, b: any) => Number(b) - Number(a))
    .map((key) => {
      return { label: formatDate(Number(key) * 1000), items: grouped[key] }
    })
  return tokenHistoryGrouped
}

export const toRuleInfo = (ruleType: number, ruleValue: number): RuleInfo => {
  if (!ruleType) {
    return {
      type: RuleType.Unknown,
      timestamp: 0,
      period: {
        type: 'month',
        value: 0,
      },
    }
  }
  if (ruleType === RuleType.AfterInactivityPeriod) {
    if (ruleValue % YEAR_IN_SECONDS === 0) {
      return {
        type: RuleType.AfterInactivityPeriod,
        timestamp: 0,
        period: {
          type: 'year',
          value: ruleValue / YEAR_IN_SECONDS,
        },
      }
    }
    return {
      type: RuleType.AfterInactivityPeriod,
      timestamp: 0,
      period: {
        type: 'month',
        value: Math.floor(ruleValue / MONTH_IN_SECONDS),
      },
    }
  }

  return {
    type: RuleType.OnSpecificDate,
    timestamp: ruleValue,
    period: {
      type: 'month',
      value: 0,
    },
  }
}

export const toSafeBalanceResponse = (balances: BalanceAPIResponse[]): SafeBalanceResponse => {
  const items = balances.map((data: any) => {
    const { tokenAddress, balance, tokenInfo } = data
    const { name, symbol, logo, decimals, usdPrice } = tokenInfo

    const info: TokenInfo = {
      type: tokenAddress === ethers.constants.AddressZero ? TokenType.NATIVE_TOKEN : TokenType.ERC20,
      address: tokenAddress,
      name,
      symbol,
      decimals,
      logoUri: logo,
    }

    const price = parseFloat(usdPrice) || 0
    const balanceUi = parseFloat(safeFormatUnits(balance, decimals)) || 0
    // console.log('price * balanceUi', price * balanceUi)
    const fiatAmount = price * balanceUi
    const fiatBalance = fiatAmount < 0.01 ? '0' : formatAmount(fiatAmount)

    return {
      tokenInfo: info,
      balance,
      fiatBalance,
      fiatConversion: '0',
    }
  })

  let sortedItems = items.sort((a, b) => {
    return parseFloat(b.fiatBalance) - parseFloat(a.fiatBalance)
  })

  const native = sortedItems.find((item) => item.tokenInfo.type === TokenType.NATIVE_TOKEN)
  if (native) {
    const nonNative = sortedItems.filter((item) => item.tokenInfo.type !== TokenType.NATIVE_TOKEN)
    sortedItems = [native, ...nonNative]
  }

  const fiatTotal = items
    .reduce((acc: any, item: any) => {
      return acc + parseFloat(item.fiatBalance)
    }, 0)
    .toString()

  return {
    fiatTotal,
    items: sortedItems,
  }
}

export const validateRule = (ruleType: number, ruleValue: number, lastActivity: number) => {
  const now = Math.floor(Date.now() / 1000)
  if (ruleType === RuleType.Unknown) return false

  if (ruleType === RuleType.OnSpecificDate) {
    return now > ruleValue
  }

  if (ruleType === RuleType.AfterInactivityPeriod) {
    return now - lastActivity > ruleValue
  }
  return false
}
