import type { Dispatch, SetStateAction } from 'react'
import { type ReactElement } from 'react'
import { useRouter } from 'next/router'
import { IconButton, Paper, Grid, Button, styled, SvgIcon, Badge } from '@mui/material'
// import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

import classnames from 'classnames'
import css from './styles.module.css'
import ConnectWallet from '@/components/common/ConnectWallet'
import NetworkSelector from '@/components/common/NetworkSelector'
// import { getSafeTokenAddress } from '@/components/common/SafeTokenWidget'
// import NotificationCenter from '@/components/notification-center/NotificationCenter'
import { AppRoutes } from '@/config/routes'
// import useChainId from '@/hooks/useChainId'
import InheritLogo from '@/public/images/logo-inherit.svg'
import InheritLogoMobile from '@/public/images/logo-round.svg'

import Link from 'next/link'
import useSafeAddress from '@/hooks/useSafeAddress'
// import BatchIndicator from '@/components/batch/BatchIndicator'
// import WalletConnect from '@/features/walletconnect/components'
// import { PushNotificationsBanner } from '@/components/settings/PushNotifications/PushNotificationsBanner'
import { FEATURES } from '@/utils/chains'
import { useHasFeature } from '@/hooks/useChains'
import useDesktop from '@/hooks/useDesktop'
import ModalButton from '@/components/common/ModalButton'
import LogoIcon from '@/public/images/inheritwallet/logo-small-color.svg'
import AvatarIcon from '@/public/images/inheritwallet/avatar.svg'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import InheritWalletModal from '@/components/common/Header/InheritWalletModal'
import { shortenAddress } from '@/utils/formatters'
import useMenuFoot from '@/hooks/useMenuFoot'

type HeaderProps = {
  onMenuToggle?: Dispatch<SetStateAction<boolean>>
  onBatchToggle?: Dispatch<SetStateAction<boolean>>
}

const Btn = styled(Button)(`
    height: 36px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    border-radius: 6px;
    // border: 1px solid var(--Blue-Ribbon-600, #5D5DFF);
    color: var(--Ebony-Clay-400, #AAB3CF);
    padding-left: 8px;
    padding-right: 4px;
`)

const Header = ({ onMenuToggle, onBatchToggle }: HeaderProps): ReactElement => {
  const menuFootOpen = useMenuFoot()
  const isDesktop = useDesktop()
  // const chainId = useChainId()
  const safeAddress = useSafeAddress()
  // const showSafeToken = safeAddress && !!getSafeTokenAddress(chainId)
  const router = useRouter()
  const enableWc = useHasFeature(FEATURES.NATIVE_WALLETCONNECT)

  // Logo link: if on Dashboard, link to Welcome, otherwise to the root (which redirects to either Dashboard or Welcome)
  // const logoHref =
  //   router.pathname === AppRoutes.welcome.index
  //     ? AppRoutes.welcome.index
  //     : router.pathname === AppRoutes.newSafe.create
  //     ? AppRoutes.newSafe.create
  //     : AppRoutes.index

  const logoHref =
    router.pathname === AppRoutes.welcome.index
      ? {
          pathname: AppRoutes.welcome.index,
          query: router.query.wallet ? { wallet: router.query.wallet } : undefined,
        }
      : {
          pathname: AppRoutes.home,
          query: router.query.wallet ? { wallet: router.query.wallet } : undefined,
        }

  const handleMenuToggle = () => {
    if (onMenuToggle) {
      onMenuToggle((isOpen) => !isOpen)
    } else {
      router.push(logoHref)
    }
  }

  // const handleBatchToggle = () => {
  //   if (onBatchToggle) {
  //     onBatchToggle((isOpen) => !isOpen)
  //   }
  // }

  return (
    <Paper className={css.container}>
      {!isDesktop && (
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            xs="auto"
            className={css.mobileLogo}
            textAlign="center"
            alignItems="center"
            justifyContent="flex-start"
            ml={!onMenuToggle ? '16px' : '8px'}
          >
            <Link href={logoHref} passHref style={{ marginLeft: '8px' }}>
              <SvgIcon component={InheritLogoMobile} inheritViewBox />
            </Link>
          </Grid>
          {safeAddress && (
            <Grid container item xs alignContent="center" justifyContent="flex-end">
              <ModalButton
                titleModal={
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                      <span className={classnames(css.inlineIndicator, css.withLogo)}>
                        <SvgIcon component={LogoIcon} inheritViewBox style={{ fontSize: '24px' }} />
                        Inherit wallet
                      </span>
                    </Grid>
                  </Grid>
                }
                contentModal={<InheritWalletModal />}
                smallSize
              >
                <Btn
                  // onClick={() => trackEvent(INHERIT_EVENTS.CLICK_SEND)}
                  startIcon={
                    <Badge
                      overlap="circular"
                      variant="standard"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      // badgeContent={
                      //   <SvgIcon
                      //     component={LogoIcon}
                      //     inheritViewBox
                      //     style={{
                      //       fontSize: '16px',
                      //       background: 'var(--Supporting-Dark-BG-800, #1F2232)',
                      //       borderRadius: '50%',
                      //       padding: '3px',
                      //     }}
                      //   />
                      // }
                    >
                      <SvgIcon component={AvatarIcon} inheritViewBox style={{ fontSize: '24px' }} />
                    </Badge>
                  }
                  // endIcon={
                  //   <SvgIcon
                  //     component={ArrowDropDownOutlinedIcon}
                  //     inheritViewBox
                  //     style={{ fontSize: '24px', color: 'var(--Ebony-Clay-400, #AAB3CF)' }}
                  //   />
                  // }
                  fullWidth
                  variant="text"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'var(--Supporting-Dark-BG-700, #272A3E)',
                    },
                    // marginRight: '16px',
                    color: 'var(--Blue-Ribbon-600, #5D5DFF)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    // border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)',
                  }}
                >
                  {safeAddress.slice(0, 6) + '...' + safeAddress.slice(-4)}
                </Btn>
              </ModalButton>
            </Grid>
          )}
        </Grid>
      )}

      {/* {isDesktop && (
        <div className={classnames(css.element, css.menuButton, !onMenuToggle ? css.hideSidebarMobile : null)}>
          <IconButton onClick={handleMenuToggle} size="large" edge="start" color="default" aria-label="menu">
            <MenuOpenIcon />
          </IconButton>
        </div>
      )} */}

      {isDesktop && (
        <div className={classnames(css.element, css.logo)}>
          <Link href={logoHref} passHref>
            <SvgIcon component={InheritLogo} inheritViewBox />
          </Link>
        </div>
      )}

      {/* {showSafeToken && (
        <div className={classnames(css.element, css.hideMobile)}>
          <SafeTokenWidget />
        </div>
      )} */}

      {/* <div className={css.element}>
        <PushNotificationsBanner>
          <NotificationCenter />
        </PushNotificationsBanner>
      </div> */}

      {/* {safeAddress && (
        <div className={classnames(css.element, css.hideMobile)}>
          <BatchIndicator onClick={handleBatchToggle} />
        </div>
      )} */}

      {/* {enableWc && (
        <div className={classnames(css.element, css.hideMobile)} style={{ color: '#7C8AB4' }}>
          <WalletConnect />
        </div>
      )} */}

      {safeAddress && isDesktop && (
        <div className={classnames(css.element)} style={{ color: '#7C8AB4', marginRight: '8px' }}>
          <ModalButton
            titleModal={
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item>
                  <span className={classnames(css.inlineIndicator, css.withLogo)}>
                    <SvgIcon component={LogoIcon} inheritViewBox style={{ fontSize: '24px' }} />
                    Inherit wallet
                  </span>
                </Grid>
              </Grid>
            }
            contentModal={<InheritWalletModal />}
            smallSize
          >
            <Btn
              // onClick={() => trackEvent(INHERIT_EVENTS.CLICK_SEND)}
              // startIcon={<SvgIcon component={LogoIcon} inheritViewBox style={{ fontSize: '28px' }} />}
              startIcon={
                <Badge
                  overlap="circular"
                  variant="standard"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  // badgeContent={
                  //   <SvgIcon
                  //     component={LogoIcon}
                  //     inheritViewBox
                  //     style={{
                  //       fontSize: '16px',
                  //       background: 'var(--Supporting-Dark-BG-800, #1F2232)',
                  //       borderRadius: '50%',
                  //       padding: '2px',
                  //       border: 'none',
                  //     }}
                  //   />
                  // }
                >
                  <SvgIcon component={AvatarIcon} inheritViewBox style={{ fontSize: '28px' }} />
                </Badge>
              }
              endIcon={
                <SvgIcon
                  component={ArrowDropDownOutlinedIcon}
                  inheritViewBox
                  style={{ fontSize: '24px', color: 'var(--Ebony-Clay-400, #AAB3CF)' }}
                />
              }
              fullWidth
              variant="text"
              sx={{
                '&:hover': {
                  backgroundColor: 'var(--Supporting-Dark-BG-700, #272A3E)',
                },
              }}
            >
              {shortenAddress(safeAddress)}
            </Btn>
          </ModalButton>
        </div>
      )}

      {router.pathname !== AppRoutes.welcome.index && (
        <div>
          <div
            className={classnames(css.element)}
            style={
              isDesktop
                ? {
                    paddingLeft: '16px',
                    borderRight: '1px solid var(--color-border-light)',
                    borderLeft: '1px solid var(--color-border-light)',
                  }
                : {}
            }
          >
            <ConnectWallet />
          </div>
        </div>
      )}

      {(isDesktop || router.pathname === AppRoutes.welcome.index) && (
        <div className={classnames(css.element, css.networkSelector)}>
          <NetworkSelector />
        </div>
      )}

      {isDesktop && router.pathname === AppRoutes.apps.open && (
        // <IconButton
        //   onClick={handleMenuToggle}
        //   size="large"
        //   edge="start"
        //   color="default"
        //   aria-label="menu"
        //   sx={{ borderRadius: 0, borderLeft: '1px solid var(--color-border-light)' }}
        // >
        //   <ExitToAppRoundedIcon style={{ color: '#7C8AB4', fontSize: '28px' }} />
        // </IconButton>
        <Link href={logoHref} passHref>
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{ borderRadius: 0, borderLeft: '1px solid var(--color-border-light)' }}
          >
            <ExitToAppRoundedIcon style={{ color: '#7C8AB4', fontSize: '28px' }} />
          </IconButton>
        </Link>
      )}

      {onMenuToggle && !menuFootOpen && (
        <Grid item xs={2}>
          <div className={classnames(css.element, css.menuButton)}>
            <IconButton
              onClick={handleMenuToggle}
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ padding: '8px' }}
            >
              <MenuOpenIcon style={{ color: '#7C8AB4', fontSize: '28px' }} />
            </IconButton>
          </div>
        </Grid>
      )}
    </Paper>
  )
}

export default Header
