import { useEffect } from 'react'
import useAsync, { type AsyncResult } from '../useAsync'
import { Errors, logError } from '@/services/exceptions'
import { useCurrentChain } from '../useChains'
// import { POLLING_INTERVAL } from '@/config/constants'
// import useIntervalCounter from '../useIntervalCounter'
import useWallet from '@/hooks/wallets/useWallet'
import { type InheritanceOfInheritor, getInheritorInfo } from '@/utils/api'

export const useLoadInheritanceOfInheritor = (refreshCounter?: number): AsyncResult<InheritanceOfInheritor[]> => {
  // const [pollCount, resetPolling] = useIntervalCounter(POLLING_INTERVAL)

  const wallet = useWallet()
  const chainInfo = useCurrentChain()

  // Re-fetch assets when the entire SafeInfo updates
  const [data, error, loading] = useAsync<InheritanceOfInheritor[]>(
    () => {
      if (!chainInfo || !wallet) return

      return getInheritorInfo(chainInfo, wallet.address)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainInfo, wallet, refreshCounter],
    false, // don't clear data between polls
  )

  // Reset the counter when safe address/chainId changes
  // useEffect(() => {
  //   resetPolling()
  // }, [resetPolling, wallet, chainInfo])

  // Log errors
  useEffect(() => {
    if (error) {
      logError(Errors._601, error.message)
    }
  }, [error])

  return [data, error, loading]
}

export default useLoadInheritanceOfInheritor
