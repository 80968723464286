import type { ReactElement } from 'react'
import { Card, CardHeader, CardContent, Box, Grid, styled, Typography } from '@mui/material'

const CardContentPadding = styled(CardContent)(`
  padding: 0px;
  &:last-child {
    padding-bottom: 16px;
  }
`)

const CardContainer = ({
  title,
  children,
  headerBtn,
  isHighlighted = false,
}: {
  title: string
  children: ReactElement
  headerBtn?: ReactElement
  isHighlighted?: boolean
}): ReactElement => {
  return (
    <Card
      sx={{
        height: '100%',
        backgroundColor: 'var(--Supporting-Dark-BG-800, #1F2232)',
        border: isHighlighted ? '1px solid var(--Blue-Ribbon-600, #5D5DFF)' : 'none',
      }}
    >
      {title !== '' && (
        <CardHeader
          sx={{ paddingY: '8px' }}
          title={
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={'auto'}>
                <Typography
                  sx={{
                    color: 'var(--Ebony-Clay-300, #D2D7E5)',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    lineHeight: '160%',
                  }}
                  variant="body1"
                  component="h2"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={'auto'}>
                <Box display="flex" justifyContent="flex-end">
                  {headerBtn}
                </Box>
              </Grid>
            </Grid>
          }
        />
      )}
      <CardContentPadding>{children}</CardContentPadding>
    </Card>
  )
}

export default CardContainer
