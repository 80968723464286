import { type ReactElement, useMemo } from 'react'
import { Box, Divider, Grid, styled, Typography, SvgIcon, IconButton, Tooltip, Skeleton } from '@mui/material'
import classnames from 'classnames'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import QrCodeButton from '@/components/sidebar/QrCodeButton'
import CopyTooltip from '@/components/common/CopyTooltip'
import useSafeAddress from '@/hooks/useSafeAddress'

import { useCurrentChain } from '@/hooks/useChains'
import css from './styles.module.css'

import EthHashInfo from '@/components/common/EthHashInfo'
import CopyIcon from '@mui/icons-material/FileCopy'
import QRCodeIcon from '@/public/images/icons/qr-code.svg'
import ViewExploreIcon from '@/public/images/icons/view-explore.svg'

import { getBlockExplorerLink } from '@/utils/chains'
import useAddressBook from '@/hooks/useAddressBookV2'
import { trackEvent, INHERIT_EVENTS } from '@/services/analytics'
import AvatarIcon from '@/public/images/inheritwallet/avatar.svg'
import LogoIcon from '@/public/images/inheritwallet/logo-small-color.svg'
import { useVisibleBalances } from '@/hooks/useVisibleBalances'
import { useAppSelector } from '@/store'
import { selectCurrency } from '@/store/settingsSlice'
import { formatCurrency } from '@/utils/formatNumber'
import { AppRoutes } from '@/config/routes'
import { useRouter } from 'next/router'

const CardContentPadding = styled(CardContent)(`
  padding: 16px;
  &:last-child {
    padding-bottom: 16px;
  }
`)

const ValueSkeleton = () => <Skeleton variant="text" width={150} />

const AccountProfile = ({
  showBalance = false,
  showHeader = true,
}: {
  showBalance?: boolean
  showHeader?: boolean
}): ReactElement => {
  const safeAddress = useSafeAddress()
  const chain = useCurrentChain()
  const link = chain ? getBlockExplorerLink(chain, safeAddress) : undefined
  const addressBook = useAddressBook()

  const currency = useAppSelector(selectCurrency)
  const { balances, loading: balancesLoading } = useVisibleBalances()
  const fiatTotal = useMemo(
    () => (balances.fiatTotal ? formatCurrency(balances.fiatTotal, currency) : ''),
    [currency, balances.fiatTotal],
  )
  const router = useRouter()

  const isShowBalance = showBalance || router.pathname !== AppRoutes.home

  return (
    <div>
      <Box flex={1} alignItems="center" justifyContent="center" p={2}>
        <Card>
          {showHeader && (
            <>
              <CardHeader
                sx={{ paddingY: '8px' }}
                title={
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                      <span className={classnames(css.inlineIndicator, css.withLogo)}>
                        <SvgIcon component={LogoIcon} inheritViewBox style={{ fontSize: '20px' }} />
                        Inherit Wallet Address
                      </span>
                    </Grid>
                  </Grid>
                }
              />
              <Divider flexItem />
            </>
          )}

          <CardContentPadding>
            <Grid container mb={1}>
              <Grid
                item
                xs={2}
                alignItems="center"
                // justifyContent="center"
                sx={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}
              >
                <SvgIcon component={AvatarIcon} inheritViewBox sx={{ width: '32px', height: '32px' }} />
              </Grid>
              <Grid item xs={10}>
                <Box pl={'12px'}>
                  <Typography
                    sx={{
                      color: 'var(--Ebony-Clay-300, #D2D7E5)',
                      fontSize: '14px',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      lineHeight: '160%',
                    }}
                    variant="body1"
                    component="h2"
                  >
                    {addressBook[safeAddress]?.name || 'Inherit Wallet'}
                  </Typography>
                  <EthHashInfo
                    address={safeAddress}
                    showName={false}
                    // shortAddress={true}
                    showAvatar={false}
                    shortenLen={8}
                  />
                  {isShowBalance && (
                    <Typography
                      sx={{
                        color: 'var(--Ebony-Clay-300, #D2D7E5)',
                        fontSize: '16px',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        lineHeight: '160%',
                      }}
                    >
                      {balancesLoading ? (
                        <ValueSkeleton />
                      ) : (
                        <>
                          ${fiatTotal.replace('USD', '')}{' '}
                          <span
                            style={{
                              color: 'var(--Ebony-Clay-700, #485581)',
                              fontWeight: '300',
                              lineHeight: '160%',
                            }}
                          >
                            USD
                          </span>
                        </>
                      )}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center" spacing={0.1}>
              <Grid item xs={4}>
                <Tooltip title={link?.title || ''} placement="bottom">
                  <IconButton
                    sx={{
                      background: 'var(--Ebony-Clay-950, #2F354B);',
                      borderRadius: '4px 0px 0px 4px',
                      width: '100%',
                    }}
                    href={link?.href || ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => trackEvent(INHERIT_EVENTS.CLICK_VIEW_ON_ETHERSCAN)}
                  >
                    <SvgIcon component={ViewExploreIcon} inheritViewBox sx={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <QrCodeButton onlyQR={true}>
                  <Tooltip title="QR code" placement="bottom">
                    <IconButton
                      sx={{
                        background: 'var(--Ebony-Clay-950, #2F354B);',
                        borderRadius: '0px 0px 0px 0px',
                        width: '100%',
                      }}
                    >
                      <SvgIcon component={QRCodeIcon} inheritViewBox sx={{ width: '16px', height: '16px' }} />
                    </IconButton>
                  </Tooltip>
                </QrCodeButton>
              </Grid>
              <Grid item xs={4}>
                <CopyTooltip text={safeAddress}>
                  <IconButton
                    sx={{
                      background: 'var(--Ebony-Clay-950, #2F354B);',
                      borderRadius: '0px 4px 4px 0px',
                      width: '100%',
                    }}
                  >
                    <CopyIcon sx={{ fontSize: '16px', color: 'var(--Ebony-Clay-300, #D2D7E5)' }} />
                  </IconButton>
                </CopyTooltip>
              </Grid>
            </Grid>
          </CardContentPadding>
        </Card>
      </Box>
    </div>
  )
}

export default AccountProfile
