export const encryptedMnemonicKey = '147240489dd6808dfa477553dd31f9c771d4d0ffce3cc6763da4465f1b8b7a8a'

export const getEncryptedMnemonic = () => {
  return localStorage.getItem(encryptedMnemonicKey)
}

export const setEncryptedMnemonic = (encrypted: string) => {
  localStorage.setItem(encryptedMnemonicKey, encrypted)
}

export const setIv = async (iv: string) => {
  await localStorage.setItem('cryptoIv', iv)
}

export const setSalt = async (salt: string) => {
  await localStorage.setItem('cryptoSalt', salt)
}

export const getIv = async () => {
  let localIV = localStorage.getItem('cryptoIv')

  if (!localIV) {
    const iv = crypto.getRandomValues(new Uint8Array(16))
    const jsonIv = JSON.stringify(iv)
    await localStorage.setItem('cryptoIv', jsonIv)
    return iv
  } else {
    const iv = new Uint8Array(Object.values(JSON.parse(localIV)))
    return iv
  }
}

export const getSalt = async () => {
  let localSalt = localStorage.getItem('cryptoSalt')
  if (!localSalt) {
    const salt = crypto.getRandomValues(new Uint8Array(16))
    const jsonSalt = JSON.stringify(salt)
    await localStorage.setItem('cryptoSalt', jsonSalt)
    return salt
  } else {
    const salt = new Uint8Array(Object.values(JSON.parse(localSalt)))
    return salt
  }
}

const getKey = async (passwordBytes: Uint8Array) => {
  const salt = await getSalt()

  const initialKey = await crypto.subtle.importKey('raw', passwordBytes, { name: 'PBKDF2' }, false, ['deriveKey'])

  return crypto.subtle.deriveKey(
    { name: 'PBKDF2', salt, iterations: 50000, hash: 'SHA-256' },
    initialKey,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt'],
  )
}

export const getCryptoParams = async (password: string): Promise<{ key: CryptoKey; iv: any }> => {
  const enc = new TextEncoder()
  const encKey = enc.encode(password)
  return { key: await getKey(encKey), iv: await getIv() }
}

export const encrypt = async (data: string, cryptoParams: { key: CryptoKey; iv: any }) => {
  const enc = new TextEncoder()
  const encData = enc.encode(data)
  const encResult = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: cryptoParams.iv,
    },
    cryptoParams.key,
    encData,
  )
  return JSON.stringify(new Uint8Array(encResult))
}

export const handleEncrypt = async (mnemonic: string, password: string): Promise<string> => {
  const cryptoParams = await getCryptoParams(password)
  const encrypted = await encrypt(mnemonic, cryptoParams)
  setEncryptedMnemonic(encrypted)
  return encrypted
}

export const decrypt = async (encryptedData: string, cryptoParams: { key: CryptoKey; iv: any }) => {
  const encryptedUint = new Uint8Array(Object.values(JSON.parse(encryptedData)))
  const contentBytes = new Uint8Array(
    await crypto.subtle.decrypt({ name: 'AES-GCM', iv: cryptoParams.iv }, cryptoParams.key, encryptedUint),
  )
  return new TextDecoder().decode(contentBytes)
}

export const handleDecrypt = async (password: string) => {
  try {
    const cryptoParams = await getCryptoParams(password)
    const encrypted = getEncryptedMnemonic()
    if (!encrypted) {
      throw new Error('No encrypted secret found')
    }
    const mnemonic = await decrypt(encrypted, cryptoParams)
    return mnemonic
  } catch (error) {
    throw new Error('Incorrect password')
  }
}
