import { getWeb3ReadOnly } from '@/hooks/wallets/web3'
import { ERC20__factory } from '@/types/contracts'
import { type TokenInfo, TokenType } from '@safe-global/safe-gateway-typescript-sdk'
import { BigNumber } from 'ethers'

import { getViewer } from '@/utils/contracts'
import { logoUrl, type TokenInfoV2 } from '@/utils/routescan'

export const UNLIMITED_APPROVAL_AMOUNT = BigNumber.from(2).pow(256).sub(1)
export const UNLIMITED_PERMIT2_AMOUNT = BigNumber.from(2).pow(160).sub(1)

/**
 * Fetches ERC20 token symbol and decimals from on-chain.
 * @param address address of erc20 token
 */
export const getERC20TokenInfoOnChain = async (
  address: string,
): Promise<Omit<TokenInfo, 'name' | 'logoUri'> | undefined> => {
  const web3 = getWeb3ReadOnly()
  if (!web3) return

  const erc20 = ERC20__factory.connect(address, web3)
  const [symbol, decimals] = await Promise.all([erc20.symbol(), erc20.decimals()])
  return {
    address,
    symbol,
    decimals,
    type: TokenType.ERC20,
  }
}

export const getERC20TokensInfoOnChain = async (chainId: string, addresses: string[]): Promise<TokenInfoV2[]> => {
  const viewer = await getViewer(chainId)

  const infos = await viewer.callStatic.getTokensInfo(addresses)

  const tokens: TokenInfoV2[] = addresses.map((address, i) => {
    const name = infos[0][i]
    const symbol = infos[1][i]
    const decimals = infos[2][i] ? BigNumber.from(infos[2][i]).toNumber() : 18
    return {
      name,
      address,
      symbol,
      decimals,
      type: TokenType.ERC20,
      logoUri: `${logoUrl}/${address}.png`,
      priceUsd: '0',
    }
  })
  return tokens
}
