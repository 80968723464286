import {
  type ReactElement,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  useState,
  Suspense,
  createContext,
} from 'react'
import dynamic from 'next/dynamic'
import { Box } from '@mui/material'
// import { INHERIT_EVENTS, trackEvent } from '@/services/analytics'

const ContentModal = dynamic(() => import('./ContentModal'))

export const ModalButtonContext = createContext<{
  setModalOpen: Dispatch<SetStateAction<boolean>>
  setTitle: Dispatch<SetStateAction<ReactNode | undefined>>
}>({
  setModalOpen: () => {},
  setTitle: () => {},
})

const ModalButton = ({
  children,
  titleModal,
  contentModal,
  display = 'grid',
  smallSize = false,
}: {
  children: ReactNode
  titleModal: ReactNode
  contentModal: ReactNode
  display?: string
  smallSize?: boolean
}): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<ReactNode | undefined>(titleModal)

  return (
    <ModalButtonContext.Provider
      value={{
        setModalOpen,
        setTitle,
      }}
    >
      <>
        <Box sx={{ width: '100%' }} display={display} onClick={() => setModalOpen(true)}>
          {children}
        </Box>

        {modalOpen && (
          <Suspense>
            <ContentModal
              dialogTitle={title ? title : titleModal}
              contentModal={contentModal}
              onClose={() => setModalOpen(false)}
              smallSize={smallSize}
            />
          </Suspense>
        )}
      </>
    </ModalButtonContext.Provider>
  )
}

export default ModalButton
