import { useAppSelector, useAppDispatch } from '@/store'
import { selectLoadingStatus, startLoading, stopLoading } from '@/store/isLoadingSlice'

const useIsLoading = () => {
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(selectLoadingStatus).isLoading

  const enableLoading = () => {
    dispatch(startLoading())
  }

  const disableLoading = () => {
    dispatch(stopLoading())
  }

  return { isLoading, enableLoading, disableLoading }
}

export default useIsLoading
