import { type ReactElement } from 'react'
import Assets from '@/components/dashboard/Assets/Assets'
import AccountProfile from '@/components/sidebar/AccountProfile'

const InheritWalletModal = (): ReactElement => {
  return (
    <>
      <AccountProfile showBalance={true} showHeader={false} />
      <Assets showTitle={false} isModal={true} />
    </>
  )
}

export default InheritWalletModal
