import { type ReactElement, type ReactNode } from 'react'
import { styled, Typography } from '@mui/material'

export const H1 = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Typography
      sx={{
        color: 'var(--Ebony-Clay-300, #D2D7E5)',
        fontSize: '20px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '160%',
      }}
      variant="body1"
      component="h2"
    >
      {children}
    </Typography>
  )
}

export const SubTitle = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Typography
      sx={{
        color: 'var(--Ebony-Clay-500, #7C8AB4)',
        fontSize: '14px',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: '160%',
      }}
    >
      {children}
    </Typography>
  )
}

export const Body2White = styled(Typography)(
  `
    color: var(--Ebony-Clay-400, #AAB3CF);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
`,
)

export const Body1White = styled(Typography)(
  `
    color: var(--Ebony-Clay-400, #AAB3CF);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 160%; 
  `,
)

export const H2White = styled(Typography)(
  `
    color: var(--Ebony-Clay-300, #D2D7E5);
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 160%; 
`,
)
