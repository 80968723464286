import { useEffect, useMemo } from 'react'
import type { SafeBalanceResponse } from '@safe-global/safe-gateway-typescript-sdk'
import { useAppSelector } from '@/store'
import useAsync, { type AsyncResult } from '../useAsync'
import { Errors, logError } from '@/services/exceptions'
import { selectSettings, TOKEN_LISTS } from '@/store/settingsSlice'
import { useCurrentChain } from '../useChains'
import { FEATURES, hasFeature } from '@/utils/chains'
// import { POLLING_INTERVAL } from '@/config/constants'
import useIntervalCounter from '../useIntervalCounter'
import useSafeInfo from '../useSafeInfo'
import { getTokenBalances } from '@/utils/api'
// import { getWeb3ReadOnly } from '@/hooks/wallets/web3'

const useTokenListSetting = (): boolean | undefined => {
  const chain = useCurrentChain()
  const settings = useAppSelector(selectSettings)

  const isTrustedTokenList = useMemo(() => {
    if (settings.tokenList === TOKEN_LISTS.ALL) return false
    return chain ? hasFeature(chain, FEATURES.DEFAULT_TOKENLIST) : undefined
  }, [chain, settings.tokenList])

  return isTrustedTokenList
}

// const getBalances = async (chain: ChainInfo, safeAddress: string) => {
//   let web3 = getWeb3ReadOnly()

//   while (!web3) {
//     console.log('Waiting for web3...')
//     await new Promise((resolve) => setTimeout(resolve, 1000))
//     web3 = getWeb3ReadOnly()
//   }
//   return getTokenBalances(chain, safeAddress)
// }

const POLLING_INTERVAL = 10000

export const useLoadBalances = (): AsyncResult<SafeBalanceResponse> => {
  const [pollCount, resetPolling] = useIntervalCounter(POLLING_INTERVAL)
  // const currency = useAppSelector(selectCurrency)
  // const isTrustedTokenList = useTokenListSetting()
  const { safe, safeAddress } = useSafeInfo()
  const chainId = safe.chainId
  const chainInfo = useCurrentChain()

  // Re-fetch assets when the entire SafeInfo updates
  const [data, error, loading] = useAsync<SafeBalanceResponse>(
    () => {
      if (!chainInfo || !chainId || !safeAddress) return

      // return getBalances(chainId, safeAddress, currency, {
      //   trusted: isTrustedTokenList,
      // })
      return getTokenBalances(chainInfo, safeAddress)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [safeAddress, chainId, pollCount],
    false, // don't clear data between polls
  )

  // Reset the counter when safe address/chainId changes
  useEffect(() => {
    resetPolling()
  }, [resetPolling, safeAddress, chainId])

  // Log errors
  useEffect(() => {
    if (error) {
      logError(Errors._601, error.message)
    }
  }, [error])

  return [data, error, loading]
}

export default useLoadBalances
