import { useEffect, useState } from 'react'
// import WalletBalance from '@/components/common/WalletBalance'
// import { WalletIdenticon } from '@/components/common/WalletOverview'
import { type BigNumber } from 'ethers'
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Divider,
  Grid,
  styled,
  // Typography,
  SvgIcon,
  // IconButton,
  // Tooltip,
} from '@mui/material'
import classnames from 'classnames'
import css from './styles.module.css'
// import SocialLoginInfo from '@/components/common/SocialLoginInfo'
// import Link from 'next/link'
// import { AppRoutes } from '@/config/routes'
// import LockIcon from '@/public/images/common/lock-small.svg'
// import EthHashInfo from '@/components/common/EthHashInfo'
// import ChainSwitcher from '@/components/common/ChainSwitcher'
// import { IS_PRODUCTION } from '@/config/constants'
// import { isSocialLoginWallet } from '@/services/mpc/SocialLoginModule'
import useOnboard, { type ConnectedWallet, switchWallet } from '@/hooks/wallets/useOnboard'
import { useRouter } from 'next/router'
import useAddressBook from '@/hooks/useAddressBook'
import { useAppSelector } from '@/store'
import { selectChainById } from '@/store/chainsSlice'
import madProps from '@/utils/mad-props'
import useSocialWallet from '@/hooks/wallets/mpc/useSocialWallet'
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import useChainId from '@/hooks/useChainId'
// import WalletIcon from '@/components/common/WalletIcon'
// import { shortenAddress } from '@/utils/formatters'
import useDesktop from '@/hooks/useDesktop'
import NetworkSelector from '@/components/common/NetworkSelector'
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
// import CopyIcon from '@/public/images/icons/copy.svg'
// import ViewExploreIcon from '@/public/images/icons/view-explore.svg'
import { getBlockExplorerLink } from '@/utils/chains'
// import CopyTooltip from '@/components/common/CopyTooltip'
import { useCurrentChain } from '@/hooks/useChains'
import EthHashInfo from '@/components/common/EthHashInfo'
import LogoutIcon from '@mui/icons-material/Logout'
import { INHERIT_EVENTS, trackEvent } from '@/services/analytics'
import AvatarIcon from '@/public/images/inheritwallet/avatar.svg'
import { useAppDispatch } from '@/store'
import { lockHDWallet } from '@/store/hdWalletSlice'
import useHDWallet from '@/hooks/wallets/useHDWallet'
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Body1White, Body2White } from '@/components/typography'
import SettingsIcon from '@mui/icons-material/Settings'

type WalletInfoProps = {
  wallet: ConnectedWallet
  balance?: string | BigNumber
  currentChainId: ReturnType<typeof useChainId>
  socialWalletService: ReturnType<typeof useSocialWallet>
  router: ReturnType<typeof useRouter>
  onboard: ReturnType<typeof useOnboard>
  addressBook: ReturnType<typeof useAddressBook>
  handleClose: () => void
}
const CardContentPadding = styled(CardContent)(`
  padding: 16px;
  &:last-child {
    padding-bottom: 16px;
  }
`)

// Update gas fees every 15 seconds
const REFRESH_DELAY = 10e3

export const WalletInfo = ({
  wallet,
  balance,
  currentChainId,
  socialWalletService,
  router,
  onboard,
  addressBook,
  handleClose,
}: WalletInfoProps) => {
  const isDesktop = useDesktop()
  const chainInfo = useAppSelector((state) => selectChainById(state, wallet.chainId))
  const prefix = chainInfo?.shortName
  const chain = useCurrentChain()
  const dispatch = useAppDispatch()
  const hdWallet = useHDWallet()

  const link = chain ? getBlockExplorerLink(chain, wallet.address) : undefined

  const [counter, setCounter] = useState<number>(0)

  const handleSwitchWallet = () => {
    if (onboard) {
      handleClose()
      switchWallet(onboard)
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => setCounter(counter + 1), REFRESH_DELAY)
    return () => clearTimeout(timerId)
  }, [counter])

  useEffect(() => {
    if (onboard) {
      onboard.state.actions.updateBalances()
    }
  }, [onboard, counter])

  const handleDisconnect = () => {
    if (!wallet) return
    trackEvent({ ...INHERIT_EVENTS.CLICK_DISCONNECT_WALLET, ...{ label: wallet.label } })

    onboard?.disconnectWallet({
      label: wallet.label,
    })

    if (hdWallet.isExisted) {
      dispatch(lockHDWallet())
    }

    handleClose()
  }

  // const isSocialLogin = isSocialLoginWallet(wallet.label)
  return (
    <div>
      <Box flex={1} alignItems="center" justifyContent="center">
        <Card sx={{ backgroundColor: 'var(--Supporting-Dark-BG-800, #1F2232)' }}>
          <CardHeader
            sx={{ paddingY: '8px', px: '16px' }}
            title={
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={6}>
                  <span className={classnames(css.inlineIndicator, css.withLogo)} style={{ fontSize: '16px' }}>
                    {/* <WalletIcon width={24} height={24} provider={wallet.label} icon={wallet.icon} /> */}
                    <SvgIcon component={SettingsIcon} inheritViewBox sx={{ width: '24px', height: '24px' }} />
                    Settings
                  </span>
                </Grid>

                <Grid item xs={6} container justifyContent="flex-end">
                  {/* {isDesktop && (
                    <IconButton
                      sx={{
                        background: 'var(--Ebony-Clay-950, #2F354B)',
                        borderRadius: '4px',
                        fontSize: '14px',
                        color: 'var(--Ebony-Clay-300, #D2D7E5)',
                        paddingX: isDesktop ? '16px' : '8px',
                      }}
                      onClick={handleSwitchWallet}
                    >
                      Switch account
                    </IconButton>
                  )} */}
                  {!isDesktop && <NetworkSelector />}
                </Grid>
              </Grid>
            }
          ></CardHeader>
          <Divider flexItem />
          <CardContentPadding sx={{ px: '16px' }}>
            <Grid
              container
              mb={2}
              sx={{
                padding: '8px',
                paddingX: '16px',
                // background: 'var(--Supporting-Dark-BG-700, #272A3E)',
                border: '1px solid var(--Ebony-Clay-950, #2F354B)',
                borderRadius: '4px',
              }}
            >
              <Grid item xs="auto" justifyContent="center" sx={{ display: 'flex', flexDirection: 'column' }}>
                <SvgIcon component={AvatarIcon} inheritViewBox sx={{ width: '36px', height: '36px' }} />
              </Grid>
              <Grid container item xs alignContent="center" ml="16px">
                <Box>
                  <Body1White sx={{ fontSize: '14px' }}>Signer address</Body1White>
                  <EthHashInfo
                    address={wallet.address}
                    showName={false}
                    shortAddress={true}
                    showAvatar={false}
                    shortenLen={6}
                    showCopyButton
                    hasExplorer
                    fontSize="14px"
                  />
                  {/* <Typography
                      sx={{
                        color: 'var(--Blue-Ribbon-600, #5D5DFF)',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                        fontStyle: 'normal',
                        lineHeight: '160%',
                        display: 'inline',
                        marginRight: '8px',
                      }}
                      variant="body1"
                      component="h2"
                    >
                      {prefix}:{shortenAddress(wallet.address, 6)}
                    </Typography> */}
                  {/* {!isDesktop && (
                      <Tooltip title="Change account" placement="bottom">
                        <IconButton
                          sx={{
                            background: 'var(--Supporting-Dark-BG-700, #272A3E)',
                            borderRadius: '4px',
                            display: 'inline',
                            paddingX: '4px',
                            paddingY: '0px',
                          }}
                        >
                          <ManageAccountsIcon sx={{ color: '#7C8AB4' }} fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                </Box>
                {/* <Typography
                    sx={{
                      color: 'var(--Ebony-Clay-300, #D2D7E5)',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                      fontStyle: 'normal',
                      lineHeight: '160%',
                    }}
                    variant="body1"
                    component="h2"
                  >
                    {balance?.toString().split(' ')[0]}{' '}
                    <span
                      style={{
                        color: 'var(--Ebony-Clay-500, #7C8AB4)',
                        fontWeight: '500',
                        lineHeight: '160%',
                      }}
                    >
                      {balance?.toString().split(' ')[1]}
                    </span>
                  </Typography> */}
              </Grid>
            </Grid>
            <Divider>
              <Body2White sx={{ color: 'var(--Ebony-Clay-500, #7C8AB4)' }}>Security & Privacy</Body2White>
            </Divider>
            {/* View on explorer */}
            {/* <Grid container alignItems="center" justifyContent="center" spacing={0.3}>
              <Grid item xs={6}>
                <Tooltip title={link?.title || ''} placement="bottom">
                  <IconButton
                    sx={{
                      background: 'var(--Supporting-Dark-BG-700, #272A3E)',
                      borderRadius: '4px 0px 0px 4px',
                      width: '100%',
                      height: '40px',
                    }}
                    href={link?.href || ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => trackEvent(INHERIT_EVENTS.CLICK_VIEW_ON_ETHERSCAN)}
                  >
                    <SvgIcon component={ViewExploreIcon} inheritViewBox sx={{ width: '16px' }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <CopyTooltip text={wallet.address}>
                  <IconButton
                    sx={{
                      background: 'var(--Supporting-Dark-BG-700, #272A3E)',
                      borderRadius: '0px 4px 4px 0px',
                      width: '100%',
                      height: '40px',
                    }}
                  >
                    <SvgIcon component={CopyIcon} inheritViewBox sx={{ width: '16px' }} fontSize="small" />
                  </IconButton>
                </CopyTooltip>
              </Grid>
            </Grid> */}
            {/* <Grid mt={'12px'} container alignItems="center" justifyContent="center">
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  height: '42px',
                  color: 'var(--Ebony-Clay-300, #D2D7E5)',
                  border: '1px solid var(--Ebony-Clay-600, #5B6B9C)',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '160%',
                  '&:hover': {
                    background: 'var(--Blue-Ribbon-600, #5D5DFF)',
                    border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)',
                  },
                }}
                // startIcon={<VisibilityOutlinedIcon />}
                onClick={handleDisconnect}
              >
                View secret key
              </Button>
            </Grid> */}
            <Grid mt={'12px'} container alignItems="center" justifyContent="center">
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  height: '42px',
                  color: 'var(--Ebony-Clay-300, #D2D7E5)',
                  border: '1px solid var(--Ebony-Clay-600, #5B6B9C)',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '160%',
                  '&:hover': {
                    background: 'var(--Blue-Ribbon-600, #5D5DFF)',
                    border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)',
                  },
                }}
                startIcon={<LogoutIcon />}
                onClick={handleDisconnect}
              >
                {hdWallet.isExisted ? 'Lock wallet' : 'Disconnect wallet'}
              </Button>
            </Grid>
          </CardContentPadding>
        </Card>
      </Box>
    </div>
  )
}

export default madProps(WalletInfo, {
  socialWalletService: useSocialWallet,
  router: useRouter,
  onboard: useOnboard,
  addressBook: useAddressBook,
  currentChainId: useChainId,
})
