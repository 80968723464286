import { useContext, useEffect, useState, type ReactElement } from 'react'
import classnames from 'classnames'

import Header from '@/components/common/Header'
import css from './styles.module.css'
import SafeLoadingError from '../SafeLoadingError'
import Footer from '../Footer'
import SideDrawer from './SideDrawer'
import { useIsSidebarRoute } from '@/hooks/useIsSidebarRoute'
import useDebounce from '@/hooks/useDebounce'
import { useRouter } from 'next/router'
import { TxModalContext } from '@/components/tx-flow'
// import BatchSidebar from '@/components/batch/BatchSidebar'
import useWalletConnected from '@/hooks/useWalletConnected'
import Backdrop from '@mui/material/Backdrop'
// import CircularProgress from '@mui/material/CircularProgress'
import { Grid, BottomNavigation, BottomNavigationAction, SvgIcon } from '@mui/material'
import HomeIcon from '@/public/images/sidebar/home.svg'
import InheritorsIcon from '@/public/images/apps/inheritance-icon.svg'
import TransactionIcon from '@/public/images/sidebar/transactions.svg'
import AppsIcon from '@/public/images/apps/apps-icon.svg'
import Link from 'next/link'
import useInheritance from '@/hooks/useInheritance'
import { RuleType } from '@/store/setUpInheritorsSlice'
import useMenuFoot from '@/hooks/useMenuFoot'
import useDesktop from '@/hooks/useDesktop'
import InheritLogo from '@/public/images/logo-center.svg'
import useIsLoading from '@/hooks/useIsLoading'

// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

const MenuFooter = () => {
  const router = useRouter()
  const [value, setValue] = useState(0)
  const inheritance = useInheritance()
  const noInheritance = inheritance.rule.type === RuleType.Unknown

  useEffect(() => {
    if (router.pathname === '/home') {
      setValue(0)
    } else if (router.pathname === '/inheritance-management' || router.pathname === '/set-up-inheritance') {
      setValue(1)
    } else if (router.pathname === '/history') {
      setValue(2)
    } else if (router.pathname === '/apps') {
      setValue(3)
    }
  }, [router])

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{
        height: 'var(--footer-height)',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: '0 -8px 10px rgba(0,0,0,.6)',
        borderTop: '1px solid var(--color-border-light)',
      }}
    >
      <BottomNavigation
        showLabels
        sx={{
          width: '100%',
          background: 'var(--Supporting-Dark-BG-800, #1F2232)',
          lineHeight: '16px',
        }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        <BottomNavigationAction
          value={0}
          sx={{
            color: 'var(--Ebony-Clay-500, #7C8AB4)',
            '.MuiBottomNavigationAction-label.Mui-selected': {
              fontSize: '0.75rem',
            },
            '& svg': {
              '& path': { fill: value === 0 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
            },
            background:
              value === 0 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
          }}
          label="Home"
          icon={
            <Link
              href={{
                pathname: 'home',
                query: { wallet: router.query.wallet },
              }}
              passHref
            >
              <HomeIcon />
            </Link>
          }
        />
        <BottomNavigationAction
          sx={{
            color: 'var(--Ebony-Clay-500, #7C8AB4)',
            '.MuiBottomNavigationAction-label.Mui-selected': {
              fontSize: '0.75rem',
            },
            '& svg': {
              '& path': { fill: value === 1 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
            },
            background:
              value === 1 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
          }}
          label="Inheritance"
          icon={
            <Link
              href={{
                pathname: noInheritance ? 'set-up-inheritance' : 'inheritance-management',
                query: { wallet: router.query.wallet },
              }}
              passHref
            >
              {/* <SvgIcon sx={{ fontSize: '24px' }} fontSize="large" component={AdminPanelSettingsIcon} inheritViewBox /> */}
              <InheritorsIcon />
            </Link>
          }
        />
        <BottomNavigationAction
          sx={{
            color: 'var(--Ebony-Clay-500, #7C8AB4)',
            '.MuiBottomNavigationAction-label.Mui-selected': {
              fontSize: '0.75rem',
            },
            '& svg': {
              '& path': { fill: value === 2 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
            },
            background:
              value === 2 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
          }}
          label="Activity"
          icon={
            <Link
              href={{
                pathname: 'history',
                query: { wallet: router.query.wallet },
              }}
              passHref
            >
              <TransactionIcon />
            </Link>
          }
        />
        <BottomNavigationAction
          sx={{
            color: 'var(--Ebony-Clay-500, #7C8AB4)',
            '.MuiBottomNavigationAction-label.Mui-selected': {
              fontSize: '0.75rem',
            },
            '& svg': {
              '& path': { fill: value === 3 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
            },
            background:
              value === 3 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
          }}
          label="DApps"
          icon={
            <Link
              href={{
                pathname: 'apps',
                query: { wallet: router.query.wallet },
              }}
              passHref
            >
              <AppsIcon />
            </Link>
          }
        />
      </BottomNavigation>
    </Grid>
  )
}

const PageLayout = ({ pathname, children }: { pathname: string; children: ReactElement }): ReactElement => {
  const menuFootOpen = useMenuFoot()
  const { safeAddressLoading } = useWalletConnected()
  const router = useRouter()
  const isSidebarRoute = useIsSidebarRoute()
  const [noSidebar, setNoSidebar] = useState<boolean>(isSidebarRoute)
  const isDesktop = useDesktop()

  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(isDesktop ? true : false)
  const [isBatchOpen, setBatchOpen] = useState<boolean>(false)
  const hideSidebar = noSidebar || !isSidebarOpen
  const { setFullWidth } = useContext(TxModalContext)
  let isAnimated = useDebounce(!noSidebar, 300)
  const { isLoading } = useIsLoading()

  if (!isSidebarRoute) isAnimated = false

  useEffect(() => {
    if (isDesktop) return

    const overflow = 100
    const scrollableEl = document.getElementById('app-scrollable')
    document.body.style.overflowY = 'hidden'
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = window.innerHeight + overflow + 'px'
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo(0, overflow)

    let ts: number | undefined
    const onTouchStart = (e: TouchEvent) => {
      ts = e.touches[0].clientY
    }

    const onTouchMove = (e: TouchEvent) => {
      if (scrollableEl) {
        const scroll = scrollableEl.scrollTop
        const te = e.changedTouches[0].clientY

        if (scroll <= 0 && ts! < te) {
          scrollableEl.classList.add(css['no-scroll'])
          scrollableEl.classList.remove(css['scrollable'])
          if (e.cancelable) {
            e.preventDefault()
          }
        } else {
          scrollableEl.classList.add(css['scrollable'])
          scrollableEl.classList.remove(css['no-scroll'])
        }
      } else {
        if (e.cancelable) {
          e.preventDefault()
        }
      }
    }
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false })
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false })

    const onScroll = () => {
      if (window.scrollY < overflow) {
        window.scrollTo(0, overflow)
        if (scrollableEl) {
          scrollableEl.scrollTo(0, 0)
        }
      }
    }
    window.addEventListener('scroll', onScroll, { passive: true })
  }, [isDesktop])

  useEffect(() => {
    const noSafeAddress = router.isReady && !router.query.wallet
    setNoSidebar(!isSidebarRoute || noSafeAddress)
  }, [isSidebarRoute, pathname, router])

  useEffect(() => {
    setFullWidth(hideSidebar)
  }, [hideSidebar, setFullWidth])

  const [value, setValue] = useState(0)

  return (
    <>
      <header className={css.header}>
        <Header onMenuToggle={noSidebar ? undefined : setSidebarOpen} onBatchToggle={setBatchOpen} />
      </header>

      {!noSidebar && <SideDrawer isOpen={isSidebarOpen} onToggle={setSidebarOpen} />}
      {/* <SideDrawer isOpen={isSidebarOpen} onToggle={setSidebarOpen} /> */}

      <div
        id="app-scrollable"
        className={classnames(css.main, css['no-scroll'], {
          [css.mainNoSidebar]: hideSidebar,
          [css.mainAnimated]: isAnimated,
        })}
        style={
          menuFootOpen
            ? {
                marginBottom: 'var(--footer-height)',
                paddingBottom: 'var(--footer-height)',
                height: '100vh',
              }
            : {}
        }
        onScroll={(e) => {
          const scrollableEl = e.currentTarget
          const scrollTop = e.currentTarget.scrollTop
          if (scrollTop < 1) {
            e.currentTarget
            scrollableEl.classList.add(css['no-scroll'])
            scrollableEl.classList.remove(css['scrollable'])
            scrollableEl.scrollTo(0, 0)
          }
        }}
      >
        <div className={css.content}>
          <SafeLoadingError>{children}</SafeLoadingError>
        </div>

        {/* <BatchSidebar isOpen={isBatchOpen} onToggle={setBatchOpen} /> */}

        <Footer />
      </div>
      {/* <div style={{ background: 'red', height: 'var(--footer-height)' }}>Menu footer</div> */}
      {/* <Grid
        container
        justifyContent="center"
        alignContent="center"
        sx={{
          height: 'var(--footer-height)',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: '0 -8px 10px rgba(0,0,0,.45098039215686275)',
          borderTop: '1px solid var(--color-border-light)',
        }}
      >
        <BottomNavigation
          showLabels
          sx={{ width: '100%', background: 'var(--Supporting-Dark-BG-800, #1F2232)' }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
        >
          <BottomNavigationAction
            value={0}
            sx={{
              color: 'var(--Ebony-Clay-500, #7C8AB4)',
              '& svg': {
                '& path': { fill: value === 0 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
              },
              background:
                value === 0 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
            }}
            label="Home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: 'var(--Ebony-Clay-500, #7C8AB4)',
              '& svg': {
                '& path': { fill: value === 1 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
              },
              background:
                value === 1 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
            }}
            label="Inheritance"
            icon={<InheritorsIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: 'var(--Ebony-Clay-500, #7C8AB4)',
              '& svg': {
                '& path': { fill: value === 2 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
              },
              background:
                value === 2 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
            }}
            label="Activity"
            icon={<TransactionIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: 'var(--Ebony-Clay-500, #7C8AB4)',
              '& svg': {
                '& path': { fill: value === 3 ? 'var(--Emerald-600, #22C55E)' : 'var(--Ebony-Clay-500, #7C8AB4)' },
              },
              background:
                value === 3 ? 'var(--Supporting-Dark-BG-700, #272A3E)' : 'var(--Supporting-Dark-BG-800, #1F2232)',
            }}
            label="DApp"
            icon={<AppsIcon />}
          />
        </BottomNavigation>
      </Grid> */}
      {menuFootOpen && <MenuFooter />}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'var(--Supporting-Dark-BG-800, #1F2232)' }}
        open={isLoading}
      >
        <Grid container>
          <Grid container item xs={12} justifyContent="center">
            <SvgIcon component={InheritLogo} inheritViewBox sx={{ width: '110px', height: '110px' }} />
          </Grid>
          {/* <Grid mt="32px" container item xs={12} justifyContent="center">
            <CircularProgress size={36} sx={{ color: '#9cacff' }} />
          </Grid> */}
        </Grid>
      </Backdrop>
    </>
  )
}

export default PageLayout
