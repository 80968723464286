import Identicon from '@/components/common/Identicon'
import { getChainLogo } from '@/config/chains'
import { Box, Badge, styled, SvgIcon, Grid } from '@mui/material'
import { Suspense } from 'react'
import type { ReactElement } from 'react'

// import EthHashInfo from '@/components/common/EthHashInfo'
import WalletIcon from '@/components/common/WalletIcon'
import type { ConnectedWallet } from '@/hooks/wallets/useOnboard'
import { useAppSelector } from '@/store'
import { selectChainById } from '@/store/chainsSlice'
// import WalletBalance from '@/components/common/WalletBalance'

import css from './styles.module.css'
import { isSocialLoginWallet } from '@/services/mpc/SocialLoginModule'
import SocialLoginInfo from '@/components/common/SocialLoginInfo'
import useDesktop from '@/hooks/useDesktop'
import useChainId from '@/hooks/useChainId'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

export const WalletIdenticon = ({ wallet, size = 32 }: { wallet: ConnectedWallet; size?: number }) => {
  return (
    <Box className={css.imageContainer}>
      <Identicon address={wallet.address} size={size} />
      <Suspense>
        <Box className={css.walletIcon}>
          <WalletIcon provider={wallet.label} icon={wallet.icon} width={size / 2} height={size / 2} />
        </Box>
      </Suspense>
    </Box>
  )
}

const fallbackChainConfig = {
  chainName: 'Unknown chain',
  chainId: '-1',
  theme: {
    backgroundColor: '#ddd',
    textColor: '#000',
  },
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

const WalletOverview = ({
  wallet,
  balance,
  showBalance,
}: {
  wallet: ConnectedWallet
  balance?: string
  showBalance?: boolean
}): ReactElement => {
  const isDesktop = useDesktop()
  const walletChain = useAppSelector((state) => selectChainById(state, wallet.chainId))

  const currentChainId = useChainId()
  const chainConfig = useAppSelector((state) => selectChainById(state, currentChainId)) || fallbackChainConfig
  const prefix = walletChain?.shortName

  const isSocialLogin = isSocialLoginWallet(wallet.label)

  if (isSocialLogin) {
    return (
      <div className={css.socialLoginInfo}>
        <SocialLoginInfo
          wallet={wallet}
          chainInfo={walletChain}
          hideActions={true}
          balance={balance}
          showBalance={showBalance}
        />
      </div>
    )
  }

  return (
    <Box className={css.container}>
      {/* <WalletIdenticon wallet={wallet} /> */}
      {/* <Box className={css.walletIcon}>
        
      </Box> */}
      {isDesktop && (
        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
          <WalletIcon provider={wallet.label} icon={wallet.icon} />
        </StyledBadge>
      )}
      {/* {isDesktop && (
        <span className={css.walletConnectedText} style={{ color: 'var(--Ebony-Clay-400, #AAB3CF)' }}>
          {wallet.label} connected
        </span>
      )} */}
      {!isDesktop && (
        <Grid container alignItems="center" sx={{ position: 'relative', marginRight: '0px' }}>
          <img
            style={{ border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)', borderRadius: '8px', padding: '3px' }}
            src={getChainLogo(chainConfig.chainId)}
            alt={`${chainConfig.chainName} Logo`}
            width={36}
            height={36}
            loading="lazy"
          />
          {/* {wallet.icon && (
            <img
              style={{
                position: 'absolute',
                border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)',
                top: '16px',
                left: '24px',
                padding: '3px',
                borderRadius: '50%',
                background: 'var(--Supporting-Dark-BG-800, #1F2232)',
              }}
              width={22}
              height={22}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(wallet.icon)}`}
              alt={`${wallet.label} logo`}
            />
          )} */}
          <SvgIcon
            component={SettingsOutlinedIcon}
            inheritViewBox
            sx={{
              width: '22px',
              height: '22px',
              position: 'absolute',
              // border: '1px solid var(--Blue-Ribbon-600, #5D5DFF)',
              top: '16px',
              left: '24px',
              padding: '2px',
              borderRadius: '50%',
              background: 'var(--Supporting-Dark-BG-800, #1F2232)',
              // color: 'var(--Ebony-Clay-500, #7C8AB4)',
              color: 'var(--Blue-Ribbon-600, #5D5DFF)',
            }}
          />
        </Grid>
      )}

      {/* <Box className={css.walletDetails}>
        <Typography variant="body2" component="div">
          {wallet.ens ? (
            <div>{wallet.ens}</div>
          ) : (
            <EthHashInfo
              prefix={prefix || ''}
              address={wallet.address}
              showName={false}
              showAvatar={false}
              avatarSize={12}
              copyAddress={false}
            />
          )}
        </Typography>

        {showBalance && (
          <Typography variant="caption" component="div" fontWeight="bold" display={{ xs: 'none', sm: 'block' }}>
            <WalletBalance balance={balance} />
          </Typography>
        )}
      </Box> */}
    </Box>
  )

  // return (
  //   <Box className={css.container}>
  //     <WalletIdenticon wallet={wallet} />

  //     <Box className={css.walletDetails}>
  //       <Typography variant="body2" component="div">
  //         {wallet.ens ? (
  //           <div>{wallet.ens}</div>
  //         ) : (
  //           <EthHashInfo
  //             prefix={prefix || ''}
  //             address={wallet.address}
  //             showName={false}
  //             showAvatar={false}
  //             avatarSize={12}
  //             copyAddress={false}
  //           />
  //         )}
  //       </Typography>

  //       {showBalance && (
  //         <Typography variant="caption" component="div" fontWeight="bold" display={{ xs: 'none', sm: 'block' }}>
  //           <WalletBalance balance={balance} />
  //         </Typography>
  //       )}
  //     </Box>
  //   </Box>
  // )
}

export default WalletOverview
