import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'

export type Inheritor = {
  name: string
  address: string
  email: string
  percentage: number
  isEditing: boolean
  isDone: boolean
  id?: number
  index?: number
}

export type RuleInfo = {
  type: RuleType
  timestamp: number
  period: {
    type: 'month' | 'year'
    value: number
  }
}

export enum RuleType {
  Unknown = 0,
  AfterInactivityPeriod = 1,
  OnSpecificDate = 2,
}

export type InheritanceStep = {
  step1: {
    status: number
    inheritors: Inheritor[]
    isDone: boolean
  }
  step2: {
    status: number
    rules: RuleInfo
    isDone: boolean
  }
  step3: {
    status: number
    isDone: boolean
  }
  activeStep: number
}

export type InheritanceStepState = {
  [chainIdAddress: string]: InheritanceStep
}

const inheritanceStepDefault: InheritanceStep = {
  step1: {
    status: 1,
    inheritors: [
      {
        name: '',
        address: '',
        email: '',
        percentage: 0,
        isEditing: true,
        isDone: false,
      },
    ],
    isDone: false,
  },
  step2: {
    status: 0,
    rules: {
      type: 0,
      timestamp: 0,
      period: {
        type: 'month',
        value: 0,
      },
    },
    isDone: false,
  },
  step3: {
    status: 0,
    isDone: false,
  },
  activeStep: 0,
}

const initialState: InheritanceStepState = {}

export const setUpInheritorsV2Slice = createSlice({
  name: 'inheritanceStep',
  initialState,
  reducers: {
    setInheritors(state, action: PayloadAction<{ chainIdAddress: string; inheritors: Inheritor[] }>) {
      const { chainIdAddress, inheritors } = action.payload

      if (!state[chainIdAddress]) state[chainIdAddress] = inheritanceStepDefault
      state[chainIdAddress].step1.inheritors = inheritors
    },
    setStep1Done(state, action: PayloadAction<{ chainIdAddress: string; isDone: boolean }>) {
      const { chainIdAddress, isDone } = action.payload
      state[chainIdAddress].step1.isDone = isDone
    },
    setActiveStep(state, action: PayloadAction<{ chainIdAddress: string; activeStep: number }>) {
      const { chainIdAddress, activeStep } = action.payload
      state[chainIdAddress].activeStep = activeStep
    },
    setRuleType(state, action: PayloadAction<{ chainIdAddress: string; ruleType: number }>) {
      const { chainIdAddress, ruleType } = action.payload
      state[chainIdAddress].step2.rules.type = ruleType
    },
    setTimestamp(state, action: PayloadAction<{ chainIdAddress: string; ruleTimestamp: number }>) {
      const { chainIdAddress, ruleTimestamp } = action.payload
      state[chainIdAddress].step2.rules.timestamp = ruleTimestamp
    },
    setPeriodType(state, action: PayloadAction<{ chainIdAddress: string; rulePeriodType: 'month' | 'year' }>) {
      const { chainIdAddress, rulePeriodType } = action.payload
      state[chainIdAddress].step2.rules.period.type = rulePeriodType
    },
    setPeriodValue(state, action: PayloadAction<{ chainIdAddress: string; rulePeriodValue: number }>) {
      const { chainIdAddress, rulePeriodValue } = action.payload
      state[chainIdAddress].step2.rules.period.value = rulePeriodValue
    },
    setStep2Done(state, action: PayloadAction<{ chainIdAddress: string; isDone: boolean }>) {
      const { chainIdAddress, isDone } = action.payload
      state[chainIdAddress].step2.isDone = isDone
    },
    setStep3Done(state, action: PayloadAction<{ chainIdAddress: string; isDone: boolean }>) {
      const { chainIdAddress, isDone } = action.payload
      state[chainIdAddress].step3.isDone = isDone
    },
  },
})

const selectAllInheritanceStep = (state: RootState): InheritanceStepState => {
  return state[setUpInheritorsV2Slice.name]
}

export const selectInheritanceStep = createSelector(
  [selectAllInheritanceStep, (_, chainIdAddress: string) => chainIdAddress],

  (allInheritanceStep, chainIdAddress): InheritanceStep => {
    const inheritanceStep = allInheritanceStep[chainIdAddress]
    return inheritanceStep ? inheritanceStep : inheritanceStepDefault
  },
)

export const {
  setInheritors,
  setStep1Done,
  setActiveStep,
  setRuleType,
  setTimestamp,
  setPeriodType,
  setPeriodValue,
  setStep2Done,
  setStep3Done,
} = setUpInheritorsV2Slice.actions
