import type { ReactElement } from 'react'
import useWallet from '@/hooks/wallets/useWallet'
import AccountCenter from '@/components/common/ConnectWallet/AccountCenter'
// import ConnectionCenter from './ConnectionCenter'
// import ConnectWalletButton from '@/components/common/ConnectWallet/ConnectWalletButton'
// import WalletLogin from '@/components/welcome/WelcomeLogin/WalletLogin'

const ConnectWallet = (): ReactElement => {
  const wallet = useWallet()

  return wallet ? <AccountCenter wallet={wallet} /> : <></>
}

export default ConnectWallet
