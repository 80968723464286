export const testNets = ['gor', 'base-gor', 'sep', 'fuji', 'bnbt', 'aSep']

export const isTestnet = (shortName: string) => {
  return testNets.includes(shortName)
}

export const faucetUrls: Record<string, string> = {
  sep: 'https://www.alchemy.com/faucets/ethereum-sepolia',
  fuji: 'https://core.app/tools/testnet-faucet/?subnet=c&token=c',
  bnbt: 'https://www.bnbchain.org/en/testnet-faucet',
  aSep: 'https://www.alchemy.com/faucets/ethereum-sepolia',
}
