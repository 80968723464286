import { ethers, utils } from 'ethers'
// import type { SafeInfo } from '@safe-global/safe-gateway-typescript-sdk'
import type { MetaTransactionData } from '@safe-global/safe-core-sdk-types'
import type { Web3Provider } from '@ethersproject/providers'
import { AddressZero } from '@ethersproject/constants'
import {
  getInheritManager,
  getInheritWalletFactory,
  getContractNetwork,
  inheritWalletInterface,
  inheritManagerInterface,
} from '@/utils/contracts'
import { type Inheritor, type RuleInfo, RuleType } from '@/store/setUpInheritorsSlice'
// import { type InheritanceInfoState } from '@/store/inheritanceInfoSlice'
import { calculateProxyAddressWithCallback } from '@/utils/proxies'
import { toRuleInfo } from '@/utils/api'

export const getEthersSigner = (provider: Web3Provider) => {
  return provider.getSigner(0)
}

type Address = `0x${string}`
type Hex = `0x${string}`

type InternalTx = {
  to: Address
  data: Hex
  value: bigint
  operation: 0 | 1
}

const enableModuleCallData = (safe4337ModuleAddress: Hex) => {
  const IEnableModule = new utils.Interface([
    {
      inputs: [
        {
          internalType: 'address[]',
          name: 'modules',
          type: 'address[]',
        },
      ],
      name: 'enableModules',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ])
  return IEnableModule.encodeFunctionData('enableModules', [[safe4337ModuleAddress]]) as Hex
}

const encodeMultiSend = (txs: InternalTx[]): Hex => {
  const data: Hex = `0x${txs.map((tx) => encodeInternalTransaction(tx)).join('')}`

  const IMultiSend = new utils.Interface([
    {
      inputs: [{ internalType: 'bytes', name: 'transactions', type: 'bytes' }],
      name: 'multiSend',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
  ])
  return IMultiSend.encodeFunctionData('multiSend', [data]) as Hex
}

const encodeInternalTransaction = (tx: InternalTx): string => {
  const encoded = utils.solidityPack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [tx.operation, tx.to, tx.value, BigInt(tx.data.slice(2).length / 2), tx.data],
  )
  return encoded.slice(2)
}

const encodeInitializer = (
  chainId: string,
  owner: string,
): {
  encodedInitializer: string
  safeMasterCopyAddress: string
} => {
  const {
    inheritWalletManagerAddress,
    safeMasterCopyAddress,
    multiSendAddress,
    safe4337ModuleAddress,
    addModuleLibAddress,
  } = getContractNetwork(chainId)
  const setupTxs: InternalTx[] = [
    {
      to: addModuleLibAddress as Hex,
      data: enableModuleCallData(safe4337ModuleAddress as Hex),
      value: BigInt(0),
      operation: 1, // 1 = DelegateCall required for enabling the module
    },
  ]

  // TODO: Pay IHR token to paymaster
  // if (erc20TokenAddress != zeroAddress && paymasterAddress != zeroAddress) {
  //   setupTxs.push({
  //     to: erc20TokenAddress,
  //     data: generateApproveCallData(paymasterAddress),
  //     value: 0n,
  //     operation: 0 // 0 = Call
  //   })
  // }

  const multiSendCallData = encodeMultiSend(setupTxs)

  const setParams = {
    owner,
    threshold: 1,
    to: multiSendAddress,
    data: multiSendCallData,
    inheritWalletManager: inheritWalletManagerAddress,
    fallbackHandler: safe4337ModuleAddress,
    paymentToken: AddressZero,
    payment: 0,
    paymentReceiver: AddressZero,
  }
  const encodedInitializer = inheritWalletInterface.encodeFunctionData('newInheritWallet', [
    setParams.inheritWalletManager,
    setParams.owner,
    setParams.threshold,
    setParams.to,
    setParams.data,
    setParams.fallbackHandler,
    setParams.paymentToken,
    setParams.payment,
    setParams.paymentReceiver,
  ])

  return {
    encodedInitializer,
    safeMasterCopyAddress,
  }
}

export const newInheritWallet = async (owner: string, chainId: string, ethersProvider: Web3Provider): Promise<any> => {
  const signer = ethersProvider.getSigner(0)
  const inheritFactory = await getInheritWalletFactory(chainId, signer)
  const { encodedInitializer, safeMasterCopyAddress } = await encodeInitializer(chainId, owner)

  // TODO: saltNonce = count number of wallets created by this owner
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)
  const saltNonce = 0
  const tx = await inheritFactory.createProxyWithCallback(
    safeMasterCopyAddress,
    encodedInitializer,
    saltNonce,
    inheritWalletManagerAddress,
  )
  return tx
}

export const calculateInheritWalletAddress = async (owner: string, chainId: string): Promise<string> => {
  const inheritFactory = await getInheritWalletFactory(chainId)
  const { encodedInitializer, safeMasterCopyAddress } = await encodeInitializer(chainId, owner)

  const { inheritWalletManagerAddress } = getContractNetwork(chainId)
  // TODO: saltNonce = count number of wallets created by this owner
  const saltNonce = 0
  const address = await calculateProxyAddressWithCallback(
    inheritFactory,
    safeMasterCopyAddress,
    encodedInitializer,
    saltNonce,
    inheritWalletManagerAddress,
  )
  return address
}

export const getInitCode = (chainId: string, owner: string): string => {
  const inheritFactory = getInheritWalletFactory(chainId)
  const { encodedInitializer, safeMasterCopyAddress } = encodeInitializer(chainId, owner)
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)
  const saltNonce = 0

  const initCode = ethers.utils.hexConcat([
    inheritFactory.address,
    inheritFactory.interface.encodeFunctionData('createProxyWithCallback', [
      safeMasterCopyAddress,
      encodedInitializer,
      saltNonce,
      inheritWalletManagerAddress,
    ]),
  ])

  return initCode
}

// export const entryPointSC = async (ethersProvider: Web3Provider, initCode: string) => {

//   try {
//     await entryPoint.functions.getSenderAddress(initCode)
//   } catch (error: any) {
//     if (error.error?.data) {
//       const errorData = error.error.data
//       console.log('error 2', errorData)
//       const selecter = errorData.slice(0, 10)
//       const err = entryPoint.interface.decodeErrorResult(selecter, errorData)
//       console.log('entryPointSC', err)
//     }
//     console.log('error 1', error, error.error, typeof error)
//   }
// }

export const inheritAssets = async (
  ethersProvider: Web3Provider,
  chainId: string,
  inheritWalletAddress: string,
  tokens: string[],
  recipient: string,
) => {
  const signer = ethersProvider.getSigner(0)
  const inheritManager = await getInheritManager(chainId, signer)
  if (!inheritManager) throw new Error('Inherit Wallet Manager not found')

  const tx = await inheritManager.inherit(inheritWalletAddress, tokens, recipient)
  return tx
}

export const getLastActivity = async (inheritWalletAddress: string, chainId: string): Promise<number> => {
  const manager = await getInheritManager(chainId)
  const lastActivity = await manager.lastActivity(inheritWalletAddress)
  return lastActivity.toNumber()
}

type InheritWalletOnChain = {
  address: string
  status: number
  ruleInfo: RuleInfo
  allocated: number
  lastActivity: number
}

export const getInheritWalletOnChain = async (chainId: string, address: string): Promise<InheritWalletOnChain> => {
  const manager = await getInheritManager(chainId)
  const inheritWallet = await manager.getInheritWallet(address)

  const ruleType = inheritWallet['rule']['ruleType']
  const ruleValue =
    ruleType === 1
      ? inheritWallet['rule']['specificDate'].toNumber()
      : inheritWallet['rule']['inactivityPeriod'].toNumber()
  const ruleInfo = toRuleInfo(ruleType, ruleValue)

  const result = {
    address: inheritWallet['inheritWallet'],
    status: inheritWallet['status'],
    ruleInfo,
    allocated: inheritWallet['allocated'],
    lastActivity: inheritWallet['lastActivity'].toNumber(),
  }
  return result
}

// export const getInheritance = async (inheritWalletAddress: string, chainId: string): Promise<InheritanceInfoState> => {
//   const inheritManager = await getInheritManager(chainId)
//   const inheritanceInfo = await inheritManager.getInheritWallet(inheritWalletAddress)

//   const indexed = inheritanceInfo.inheritors.map((inheritor: any, index: number) => {
//     return {
//       ...inheritor,
//       index,
//     }
//   })
//   const sorted = indexed.slice().sort((a: any, b: any) => {
//     return a.id.toNumber() - b.id.toNumber()
//   })

//   const result = {
//     status: inheritanceInfo.extra.status,
//     allocated: inheritanceInfo.extra.allocated,
//     latestId: inheritanceInfo.extra.latestId,
//     rule: {
//       type: inheritanceInfo.rule.ruleType,
//       timestamp: inheritanceInfo.rule.specificDate.toNumber() * 1000,
//       period: {
//         type: 'month',
//         value: inheritanceInfo.rule.inactivityPeriod / (86400 * 30),
//       },
//     },
//     inheritors: sorted.map((inheritor: any, index: number) => {
//       return {
//         name: `Inheritor ${index + 1}`,
//         address: inheritor.inheritor,
//         email: '',
//         percentage: inheritor.percentage / 100,
//         isEditing: false,
//         isDone: true,
//         id: inheritor.id.toNumber(),
//         index: inheritor.index,
//       }
//     }),
//     updatedAt: Date.now(),
//   }

//   return result as InheritanceInfoState
// }

// encode functions
export const encodeNewInheritorsFunc = (
  inheritors: Inheritor[],
  rule: RuleInfo,
  chainId: string,
): MetaTransactionData => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)

  const params = {
    inheritors: inheritors.map((inheritor) => inheritor.address),
    percentages: inheritors.map((inheritor) => inheritor.percentage * 100),
    ..._parseRuleInfo(rule),
  }
  const data = inheritManagerInterface.encodeFunctionData('newInheritance', [
    params.inheritors,
    params.percentages,
    params.ruleType,
    params.specificDate,
    params.inactivityPeriod,
  ])
  return {
    to: inheritWalletManagerAddress,
    value: '0',
    data,
  }
}

export const encodeAddInheritorFunc = (inheritor: string, percentage: number, chainId: string): MetaTransactionData => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)

  const params = {
    inheritor,
    percentage: percentage * 100,
  }
  const data = inheritManagerInterface.encodeFunctionData('addInheritor', [params.inheritor, params.percentage])
  return {
    to: inheritWalletManagerAddress,
    value: '0',
    data,
  }
}

export const encodeRmInheritorFunc = (chainId: string, inheritor: string): MetaTransactionData => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)

  const data = inheritManagerInterface.encodeFunctionData('rmInheritor', [inheritor])
  return {
    to: inheritWalletManagerAddress,
    value: '0',
    data,
  }
}

export const encodeChangeInheritorFunc = (
  chainId: string,
  inheritor: string,
  newInheritor: string,
  percentage: number,
): MetaTransactionData => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)

  const data = inheritManagerInterface.encodeFunctionData('changeInheritor', [
    inheritor,
    newInheritor,
    percentage * 100,
  ])
  return {
    to: inheritWalletManagerAddress,
    value: '0',
    data,
  }
}

export const encodeSetRuleFunc = (rule: RuleInfo, chainId: string): MetaTransactionData => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)

  const params = _parseRuleInfo(rule)
  const data = inheritManagerInterface.encodeFunctionData('setRule', [
    params.ruleType,
    params.specificDate,
    params.inactivityPeriod,
  ])
  return {
    to: inheritWalletManagerAddress,
    value: '0',
    data,
  }
}

const _parsePeriodValue = (period: RuleInfo['period']) => {
  return period.value * (period.type === 'month' ? 30 : 365) * 86400
}

const _parseRuleInfo = (rule: RuleInfo) => {
  return {
    ruleType: rule.type,
    specificDate: rule.type === RuleType.OnSpecificDate && rule.timestamp ? Math.floor(rule.timestamp / 1000) : 0,
    inactivityPeriod: rule.type === RuleType.AfterInactivityPeriod ? _parsePeriodValue(rule.period) : 0,
  }
}
