import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '@/store'
import { selectHDWallet, enableHDWallet } from '@/store/hdWalletSlice'
import { getEncryptedMnemonic } from '@/utils/crypto'

const useHDWallet = () => {
  const hdWallet = useAppSelector(selectHDWallet)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const encrypted = getEncryptedMnemonic()
    if (encrypted) {
      dispatch(enableHDWallet())
    }
  }, [])

  return hdWallet
}

export default useHDWallet
