import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface Inheritor {
  name: string
  address: string
  email: string
  percentage: number
  isEditing: boolean
  isDone: boolean
  id?: number
  index?: number
  claimed?: boolean
}

export interface RuleInfo {
  type: RuleType
  timestamp: number
  period: {
    type: 'month' | 'year'
    value: number
  }
}

export enum RuleType {
  Unknown = 0,
  AfterInactivityPeriod = 1,
  OnSpecificDate = 2,
}

export interface InheritorsState {
  step1: {
    status: number
    inheritors: Inheritor[]
    isDone: boolean
  }
  step2: {
    status: number
    rules: RuleInfo
    isDone: boolean
  }
  step3: {
    status: number
    isDone: boolean
  }
  activeStep: number
}

const initialState: InheritorsState = {
  step1: {
    status: 1,
    inheritors: [
      {
        name: '',
        address: '',
        email: '',
        percentage: 0,
        isEditing: true,
        isDone: false,
      },
    ],
    isDone: false,
  },
  step2: {
    status: 0,
    rules: {
      type: 0,
      timestamp: 0,
      period: {
        type: 'month',
        value: 0,
      },
    },
    isDone: false,
  },
  step3: {
    status: 0,
    isDone: false,
  },
  activeStep: 0,
}

export const setUpInheritorsSlice = createSlice({
  name: 'setUpInheritors',
  initialState,
  reducers: {
    setInheritors(state, action: PayloadAction<Inheritor[]>) {
      state.step1.inheritors = action.payload
    },
    setStep1Done(state, action: PayloadAction<boolean>) {
      state.step1.isDone = action.payload
    },
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload
    },
    setRuleType(state, action: PayloadAction<number>) {
      state.step2.rules.type = action.payload
    },
    setTimestamp(state, action: PayloadAction<number>) {
      state.step2.rules.timestamp = action.payload
    },
    setPeriodType(state, action: PayloadAction<'month' | 'year'>) {
      state.step2.rules.period.type = action.payload
    },
    setPeriodValue(state, action: PayloadAction<number>) {
      state.step2.rules.period.value = action.payload
    },
    setStep2Done(state, action: PayloadAction<boolean>) {
      state.step2.isDone = action.payload
    },
    setStep3Done(state, action: PayloadAction<boolean>) {
      state.step3.isDone = action.payload
    },
    setInheritance(state, action: PayloadAction<InheritorsState>) {
      state.step1 = action.payload.step1
      state.step2 = action.payload.step2
      state.step3 = action.payload.step3
      state.activeStep = action.payload.activeStep
    },
  },
})

export const {
  setInheritors,
  setStep1Done,
  setActiveStep,
  setRuleType,
  setTimestamp,
  setPeriodType,
  setPeriodValue,
  setStep2Done,
  setStep3Done,
  setInheritance,
} = setUpInheritorsSlice.actions
