import { EventType } from '@/services/analytics/types'

export const INHERIT_CATEGORY = 'inherit'

export const INHERIT_EVENTS = {
  CREATE_WALLET: {
    action: 'Create new wallet',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CREATE_WALLET_FAILED: {
    action: 'Create new wallet failed',
    category: INHERIT_CATEGORY,
    event: EventType.WALLET_CREATION,
  },
  CREATE_WALLET_SUCCESS: {
    action: 'Create new wallet success',
    category: INHERIT_CATEGORY,
    event: EventType.WALLET_CREATION,
  },
  CLICK_FAUCET: {
    action: 'Click faucet',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_SEND: {
    action: 'Click send',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_RECEIVE: {
    action: 'Click receive',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_SET_UP_INHERITANCE: {
    action: 'Click inheritance btn',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_QR_CODE: {
    action: 'Click QR code',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_VIEW_ON_ETHERSCAN: {
    action: 'Click view on etherscan',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_COPY_ADDRESS: {
    action: 'Click copy address',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_APPS: {
    action: 'Click apps',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  MODAL_OPENED: {
    action: 'Modal opened',
    category: INHERIT_CATEGORY,
    event: EventType.MODAL_OPENED,
  },
  CLICK_DISCONNECT_WALLET: {
    action: 'Click disconnect wallet',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CHANGE_HISTORY_TAB: {
    action: 'Change history tab',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_CANCEL_SET_UP_INHERITANCE: {
    action: 'Click cancel set up inheritance',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  SET_UP_STEP_DONE: {
    action: 'Set up step done',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  ADDED_INHERITOR: {
    action: 'Added inheritor',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  ADDED_RULE: {
    action: 'Added rule',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  CLICK_CONFIRM_SET_UP_INHERITANCE: {
    action: 'Click confirm set up inheritance',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  SEND_TX_FAILED: {
    action: 'Send tx failed',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  SEND_TX_SUCCESS: {
    action: 'Send tx success',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  CLAIM_INHERITANCE: {
    action: 'Claim inheritance',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLAIM_INHERITANCE_FAILED: {
    action: 'Claim inheritance failed',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  CLAIM_INHERITANCE_SUCCESS: {
    action: 'Claim inheritance success',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
  CLICK_ADD_INHERITOR: {
    action: 'Click add inheritor',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_EDIT_RULE: {
    action: 'Click edit rule',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_EDIT_INHERITOR: {
    action: 'Click edit inheritor',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CLICK_DELETE_INHERITOR: {
    action: 'Click delete inheritor',
    category: INHERIT_CATEGORY,
    event: EventType.CLICK,
  },
  CHANGED_RULE: {
    action: 'Changed rule',
    category: INHERIT_CATEGORY,
    event: EventType.META,
  },
}
