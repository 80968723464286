import { useEffect, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import useWallet from '@/hooks/wallets/useWallet'
// import useConnectWallet from '@/components/common/ConnectWallet/useConnectWallet'
import { AppRoutes } from '@/config/routes'
import useOwnedSafe from '@/hooks/useOwnedSafe'
// import useChainId from '@/hooks/useChainId'
import { useCurrentChain } from '@/hooks/useChains'
import useIsWrongChain from '@/hooks/useIsWrongChain'
import useOnboard from '@/hooks/wallets/useOnboard'
import { switchWalletChain } from '@/services/tx/tx-sender/sdk'
// import useIsSafeOwner from '@/hooks/useIsSafeOwner'
import useHDWallet from '@/hooks/wallets/useHDWallet'
// import useSafeInfo from '@/hooks/useSafeInfo'
// import { getInitCode } from '@/utils/inherit-manager'

const useWalletConnected = () => {
  const router = useRouter()
  const onboard = useOnboard()
  const wallet = useWallet()
  const { safe, error, loading } = useOwnedSafe()
  // const chainId = useChainId()
  const currentChain = useCurrentChain()
  const isWrongChain = useIsWrongChain()
  // const isSafeOwner = useIsSafeOwner()
  const hdWallet = useHDWallet()
  const [safeAddressLoading, setSafeAddressLoading] = useState<boolean>(false)

  useEffect(() => {
    const hasWallet = !!wallet
    console.log('version_1.0.0')
    console.log('useWalletConnected', { hasWallet, loading, safe, error })

    // if (isWrongChain) {
    //   handleChainSwitch()
    // }

    if (!hasWallet) {
      if (router.pathname !== AppRoutes.welcome.index) {
        router.push({ pathname: AppRoutes.welcome.index, query: {} })
      }
    } else {
      if (hdWallet.isExisted && hdWallet.mnemonic === '') {
        router.push({ pathname: AppRoutes.welcome.index, query: {} })
      }
    }

    // if (hasWallet) {
    //   if (!safe) {
    //     setSafeAddressLoading(true)
    //   } else {
    //     setSafeAddressLoading(false)
    //   }
    // }

    // if (hasWallet && !loading && safe !== undefined) {
    //   const hasNoSafes = safe === ''

    //   if (hasNoSafes) {
    //     router.push({ pathname: AppRoutes.newSafe.create, query: {} })
    //   } else {
    //     if (router.pathname === AppRoutes.welcome.index || router.pathname === AppRoutes.newSafe.create) {
    //       router.push({ pathname: AppRoutes.home, query: { wallet: safe } })
    //     } else if (!router.query.wallet || router.query.wallet !== safe) {
    //       router.push({ pathname: router.pathname, query: { wallet: safe } })
    //     }
    //   }
    // }
  }, [wallet, hdWallet])

  const handleChainSwitch = useCallback(async () => {
    if (!onboard || !currentChain) return

    await switchWalletChain(onboard, currentChain.chainId)
  }, [currentChain, onboard])

  return { safeAddress: safe, safeAddressLoading: safeAddressLoading || loading, safeAddressError: error }
}

export default useWalletConnected
