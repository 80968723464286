import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Inheritor, type RuleInfo, RuleType } from '@/store/setUpInheritorsSlice'

export interface InheritanceInfoState {
  status: number
  allocated: number
  rule: RuleInfo
  inheritors: Inheritor[]
  updatedAt: number
  claimed: number
  lastActivity: number
}

const initialState: InheritanceInfoState = {
  status: 0,
  allocated: 0,
  rule: {
    type: RuleType.Unknown,
    timestamp: 0,
    period: {
      type: 'month',
      value: 0,
    },
  },
  inheritors: [],
  updatedAt: 0,
  claimed: 0,
  lastActivity: 0,
}

export const inheritanceInfoSlice = createSlice({
  name: 'inheritanceInfo',
  initialState: {
    data: initialState,
    isUpdated: false,
  },
  reducers: {
    setInheritanceInfo: (state, action: PayloadAction<InheritanceInfoState>) => {
      state.data.status = action.payload.status
      state.data.allocated = action.payload.allocated
      state.data.rule = action.payload.rule
      state.data.inheritors = action.payload.inheritors
      state.data.updatedAt = action.payload.updatedAt
      state.data.claimed = action.payload.claimed
      state.data.lastActivity = action.payload.lastActivity
    },
    refreshInheritanceInfo: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload
    },
  },
})

export const { setInheritanceInfo, refreshInheritanceInfo } = inheritanceInfoSlice.actions
