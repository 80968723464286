import { Wallet } from 'ethers'

const HardenedBit = 0x80000000

export const getAccountPath = (index: number): string => {
  if (typeof index !== 'number' || index < 0 || index >= HardenedBit || index % 1) {
    throw new Error(`invalid account index, ${index}`)
  }
  return `m/44'/60'/0'/0/${index}`
}

export const generateMnemonic = (): string => {
  return Wallet.createRandom().mnemonic.phrase
}

export const walletFromMnemonic = (mnemonic: string, index: number): Wallet => {
  const path = getAccountPath(index)
  return Wallet.fromMnemonic(mnemonic, path)
}
