import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export type HDWallet = {
  mnemonic: string
  isExisted: boolean
}

const initialState: HDWallet = {
  mnemonic: '',
  isExisted: false,
}

export const hdWalletSlice = createSlice({
  name: 'hdWallet',
  initialState,
  reducers: {
    enableHDWallet: (state) => {
      state.isExisted = true
    },
    unlockHDWallet: (state, action: PayloadAction<{ mnemonic: string }>) => {
      state.mnemonic = action.payload.mnemonic
      state.isExisted = true
    },
    lockHDWallet: (state) => {
      state.mnemonic = ''
    },
  },
})

export const selectHDWallet = createSelector(
  (state: RootState) => state.hdWallet,
  (hdWallet) => hdWallet,
)

export const { unlockHDWallet, lockHDWallet, enableHDWallet } = hdWalletSlice.actions
