import { createContext, type ReactElement, type ReactNode, useEffect, useState } from 'react'
import useOnboard, {
  type ConnectedWallet,
  getConnectedWallet,
  // getConnectedWalletFromHDWallet,
} from '@/hooks/wallets/useOnboard'
// import useHDWallet from '@/hooks/wallets/useHDWallet'
// import { useCurrentChain } from '@/hooks/useChains'

export const WalletContext = createContext<ConnectedWallet | null>(null)

const WalletProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const onboard = useOnboard()
  const onboardWallets = onboard?.state.get().wallets || []
  const [wallet, setWallet] = useState<ConnectedWallet | null>(getConnectedWallet(onboardWallets))
  // const hdWallet = useHDWallet()

  useEffect(() => {
    // console.log('hdWallet', hdWallet, wallet)
    // if (!chainInfo) return

    // if (hdWallet.isExisted) {
    //   setWallet(null)
    //   return
    // }

    if (!onboard) return

    const walletSubscription = onboard.state.select('wallets').subscribe((wallets) => {
      const newWallet = getConnectedWallet(wallets)
      setWallet(newWallet)
    })

    return () => {
      walletSubscription.unsubscribe()
    }
  }, [onboard])

  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
}

export default WalletProvider
