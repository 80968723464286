// import { utils } from 'ethers'
// import { toUtf8Bytes } from '@ethersproject/strings'
import type { WebAppTypesV2 as WebAppTypes } from '@tunghm/twa-dev-sdk/dist/sdk'

type CloudStorageKey = string
type CloudStorageValue = string

interface CloudStorageItems {
  [key: CloudStorageKey]: CloudStorageValue
}

export const getTWA = async () => {
  if (typeof window !== 'undefined') {
    const { WebApp } = await import('@tunghm/twa-dev-sdk')
    return WebApp
  }
}

export const getInitBioTWA = async (): Promise<WebAppTypes> => {
  return new Promise(async (resolve, reject) => {
    const twa = await getTWA()
    if (!twa) return reject('TWA not available')

    const { init, isInited } = twa.BiometricManager
    if (!isInited) {
      init(() => {
        resolve(twa)
      })
    } else {
      resolve(twa)
    }
  })
}

export const WALLET_KEY_PREFIX = 'wallet'
// roles: 'device', 'cloud', 'backup'
export const encodeWalletKey = (signer: string, role: string): string => {
  return `${WALLET_KEY_PREFIX}_${signer}_${role}`
}

export const decodeWalletKey = (key: string): { signer: string; role: string } => {
  const [signer, role] = key.replace(WALLET_KEY_PREFIX, '').split('_')
  return { signer, role }
}

export const getKeysCloudAsync = async (): Promise<string[]> => {
  return new Promise(async (resolve, reject) => {
    const twa = await getTWA()
    if (!twa) return reject('TWA not available')

    const { CloudStorage, version } = twa
    if (version === '6.0') return reject('Version 6.0 not supported')
    const { getKeys } = CloudStorage

    getKeys((error, keys) => {
      if (error) {
        return reject(error)
      }
      if (!keys) {
        return reject('No keys found')
      }
      return resolve(keys)
    })
  })
}

export const getValueCloud = async (key: CloudStorageKey): Promise<CloudStorageValue> => {
  return new Promise(async (resolve, reject) => {
    const twa = await getTWA()
    if (!twa) return reject('TWA not available')
    const { CloudStorage, version } = twa
    if (version === '6.0') return reject('Version 6.0 not supported')
    const { getItem } = CloudStorage
    try {
      getItem(key, (error, value) => {
        if (error) {
          return reject(error)
        }
        if (!value) {
          return reject('No value found')
        }
        return resolve(value)
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const setValueCloud = async (key: CloudStorageKey, value: CloudStorageValue): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const twa = await getTWA()
    if (!twa) return reject('TWA not available')

    const { CloudStorage, version } = twa
    if (version === '6.0') return reject('Version 6.0 not supported')
    const { setItem } = CloudStorage

    setItem(key, value, (error, success) => {
      if (error) {
        return reject(error)
      }
      if (!success) {
        return reject('Failed to set value')
      }
      return resolve()
    })
  })
}

export const getValuesCloud = async (keys: string[]): Promise<CloudStorageItems> => {
  return new Promise(async (resolve, reject) => {
    const twa = await getTWA()
    if (!twa) return reject('TWA not available')

    const { CloudStorage, version } = twa
    if (version === '6.0') return reject('Version 6.0 not supported')
    const { getItems } = CloudStorage

    getItems(keys, (error, items) => {
      if (error) {
        return reject(error)
      }
      if (!items) {
        return reject('No items found')
      }
      return resolve(items)
    })
  })
}
