import { useEffect, useMemo } from 'react'
import { getInheritance } from '@/utils/api'
import useSafeAddress from '@/hooks/useSafeAddress'
import { useCurrentChain } from '@/hooks/useChains'
import { useAppSelector, useAppDispatch, type RootState } from '@/store'
import { setInheritanceInfo, refreshInheritanceInfo, type InheritanceInfoState } from '@/store/inheritanceInfoSlice'

// TODO: useAsync
const useInheritance = () => {
  const dispatch = useAppDispatch()
  const safeAddress = useSafeAddress()
  const chainInfo = useCurrentChain()
  const inheritanceInfo = useAppSelector((state: RootState) => state.inheritanceInfo.data)
  const isUpdated = useAppSelector((state: RootState) => state.inheritanceInfo.isUpdated)

  useEffect(() => {
    if (!chainInfo) return

    getInheritance(chainInfo, safeAddress)
      .then((info: InheritanceInfoState) => {
        dispatch(setInheritanceInfo(info))
        dispatch(refreshInheritanceInfo(true))
      })
      .catch((error: Error) => {
        // console.log('Inheritors error:', error)
      })
  }, [safeAddress, chainInfo, dispatch, isUpdated])

  return useMemo(() => {
    return inheritanceInfo
  }, [inheritanceInfo])
}

export default useInheritance
