import { ethers } from 'ethers'
import { getWeb3ReadOnly } from '@/hooks/wallets/web3'
import {
  InheritWallet__factory,
  InheritWalletManager__factory,
  ProxyFactory__factory,
  Viewer__factory,
} from '@/typechain/contracts'

import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'

import contracts from '@/public/contracts/contractNetwork.json'

import { getContractInfo } from '@/utils/api'

export interface ContractNetworkConfig {
  inheritWalletManagerAddress: string
  /** safeMasterCopyAddress - Address of the GnosisSafe Master Copy contract deployed on a specific network */
  safeMasterCopyAddress: string

  /** safeProxyFactoryAddress - Address of the GnosisSafeProxyFactory contract deployed on a specific network */
  safeProxyFactoryAddress: string

  /** multiSendAddress - Address of the MultiSend contract deployed on a specific network */
  multiSendAddress: string

  /** multiSendCallOnlyAddress - Address of the MultiSendCallOnly contract deployed on a specific network */
  multiSendCallOnlyAddress: string

  /** fallbackHandlerAddress - Address of the Fallback Handler contract deployed on a specific network */
  fallbackHandlerAddress: string

  /** signMessageLibAddress - Address of the SignMessageLib contract deployed on a specific network */
  signMessageLibAddress: string

  /** createCallAddress - Address of the CreateCall contract deployed on a specific network */
  createCallAddress: string

  viewerAddress: string

  safe4337ModuleAddress: string

  addModuleLibAddress: string
}

export interface ContractNetworksConfig {
  /** id - Network id */
  [id: string]: ContractNetworkConfig
}

type Provider = ethers.providers.JsonRpcSigner | ethers.providers.JsonRpcProvider

export const inheritManagerInterface = InheritWalletManager__factory.createInterface()
export const inheritWalletInterface = InheritWallet__factory.createInterface()

export const getContractNetwork = (chainId: string): ContractNetworkConfig => {
  return (contracts as ContractNetworksConfig)[chainId]
}

export const getProvider = (signer?: ethers.providers.JsonRpcSigner): Provider => {
  if (signer) return signer

  const web3 = getWeb3ReadOnly()
  if (!web3) throw new Error('Web3 provider not found')
  return web3
}

// export const getEntryPoint = async (chainId: string, signer?: ethers.providers.JsonRpcSigner) => {
//   const address = '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789'
//   return EntryPoint__factory.connect(address, getProvider(signer))
// }

export const getInheritWallet = async (address: string, signer?: ethers.providers.JsonRpcSigner) => {
  return InheritWallet__factory.connect(address, getProvider(signer))
}

export const getInheritWalletFactory = (chainId: string, signer?: ethers.providers.JsonRpcSigner) => {
  const { safeProxyFactoryAddress } = getContractNetwork(chainId)
  return ProxyFactory__factory.connect(safeProxyFactoryAddress, getProvider(signer))
}

export const getInheritManager = async (chainId: string, signer?: ethers.providers.JsonRpcSigner) => {
  const { inheritWalletManagerAddress } = getContractNetwork(chainId)
  return InheritWalletManager__factory.connect(inheritWalletManagerAddress, getProvider(signer))
}

export const getViewer = async (chainId: string, signer?: ethers.providers.JsonRpcSigner) => {
  const { viewerAddress } = getContractNetwork(chainId)
  return Viewer__factory.connect(viewerAddress, getProvider(signer))
}

export const CONTRACT_NAMES = {
  INHERIT_MANAGER: 'InheritManager',
  PROXY_FACTORY: 'ProxyFactory',
  SINGLETON: 'Singleton',
  VIEWER: 'Viewer',
}

const abis: { [key: string]: string } = {
  '0x095ea7b3': 'approve(address spender, uint256 value)',
  '0xbaa2abde':
    'removeLiquidity(address tokenA, address tokenB, uint256 liquidity, uint256 amountAMin, uint256 amountBMin, address to, uint256 deadline)',
  '0x472b43f3':
    'swapExactTokensForTokens(uint256 amountIn, uint256 amountOutMin, address[] path, address to, uint256 deadline)',
  '0x5ae401dc': 'multicall(uint256 deadline, bytes[] data)',
}

// export const parseContractData = async (
//   chainInfo: ChainInfo,
//   contractAddress: string,
//   data: string,
// ): Promise<ethers.utils.TransactionDescription> => {
//   // const requestUrl = chainInfo.blockExplorerUriTemplate.api
//   //   .replace('{{module}}', 'contract')
//   //   .replace('{{action}}', 'getabi')
//   //   .replace('{{address}}', contractAddress)

//   // console.log('requestUrl', requestUrl)

//   // const response = await fetch(requestUrl)

//   // if (!response.ok) throw 'Error: fetch abi from explorer api'
//   // const json = await response.json()
//   const selector = data.slice(0, 10)
//   const abi = abis[selector]
//   if (!abi) throw 'Error: fetch abi from explorer api'

//   const contract = new ethers.utils.Interface([`function ${abi}`])
//   console.log('contract', contract)
//   const parsed = contract.parseTransaction({ data })
//   console.log('parsed', parsed)

//   return parsed
// }

export const parseContractData = async (
  chainInfo: ChainInfo,
  contractAddress: string,
  data: string,
): Promise<{ contractName: string; parsedInput: ethers.utils.TransactionDescription }> => {
  try {
    const { abi, name } = await getContractInfo(chainInfo.chainId, contractAddress)
    const contract = new ethers.utils.Interface(abi)
    const parsed = contract.parseTransaction({ data })

    return {
      contractName: name,
      parsedInput: parsed,
    }
  } catch (e) {
    throw 'Error: fetch abi from explorer api'
  }
}
