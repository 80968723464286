import { useMemo } from 'react'
import useAsync from './useAsync'
import useWallet from '@/hooks/wallets/useWallet'
import useChainId from './useChainId'
// import { getInheritManager } from '@/utils/contracts'
import { calculateInheritWalletAddress } from '@/utils/inherit-manager'

export const getOwnedSafe = async (chainId: string, walletAddress: string) => {
  // const inheritManager = await getInheritManager(chainId)
  try {
    // const wallet = await inheritManager.getInheritWalletOf(walletAddress, 0)
    // if (!wallet[0]) throw new Error('No wallets found')
    //   return wallet[0]
    const address = await calculateInheritWalletAddress(walletAddress, chainId)
    return address
  } catch (error) {
    // console.log('getOwnedSafe', error)
  }
  return ''
}

const useOwnedSafe = (): { safe?: string; error?: string; loading: boolean } => {
  const chainId = useChainId()
  const { address: walletAddress } = useWallet() || {}

  const [data, error, loading] = useAsync<string>(() => {
    if (!walletAddress || !chainId) return

    return getOwnedSafe(chainId, walletAddress)
  }, [chainId, walletAddress])

  return useMemo(
    () => ({
      safe: data,
      error: error?.message,
      loading,
    }),
    [data, error, loading],
  )
}

export default useOwnedSafe
