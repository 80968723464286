import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export type LoadingStatus = {
  isLoading: boolean
}

const initialState: LoadingStatus = {
  isLoading: false,
}

export const isLoadingSlice = createSlice({
  name: 'isLoading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
})

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.isLoading,
  (isLoading) => isLoading,
)

export const { startLoading, stopLoading } = isLoadingSlice.actions
