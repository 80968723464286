import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { validateAddress } from '@/utils/validation'
import pickBy from 'lodash/pickBy'
import type { RootState } from '.'

export type AddressInfo = {
  name: string
  email: string
}

export type AddressBook = { [address: string]: AddressInfo }

export type AddressBookState = { [chainId: string]: AddressBook }

const initialState: AddressBookState = {}

export const addressBookV2Slice = createSlice({
  name: 'addressBookV2',
  initialState,
  reducers: {
    migrate: (state, action: PayloadAction<AddressBookState>): AddressBookState => {
      // Don't migrate if there's data already
      if (Object.keys(state).length > 0) return state
      // Otherwise, migrate
      return action.payload
    },

    setAddressBook: (_, action: PayloadAction<AddressBookState>): AddressBookState => {
      return action.payload
    },

    upsertAddressBookEntry: (
      state,
      action: PayloadAction<{ chainId: string; address: string; name: string; email: string }>,
    ) => {
      const { chainId, address, name, email } = action.payload
      if (name.trim() === '') {
        return
      }
      if (!state[chainId]) state[chainId] = {}

      state[chainId][address] = {
        name,
        email: email || '',
      }
    },

    removeAddressBookEntry: (state, action: PayloadAction<{ chainId: string; address: string }>) => {
      const { chainId, address } = action.payload
      if (!state[chainId]) return state
      delete state[chainId][address]
      if (Object.keys(state[chainId]).length > 0) return state
      delete state[chainId]
    },
  },
})

export const { setAddressBook, upsertAddressBookEntry, removeAddressBookEntry } = addressBookV2Slice.actions

export const selectAllAddressBooks = (state: RootState): AddressBookState => {
  return state[addressBookV2Slice.name]
}

export const selectAddressBookByChain = createSelector(
  [selectAllAddressBooks, (_, chainId: string) => chainId],
  (allAddressBooks, chainId): AddressBook => {
    const chainAddresses = allAddressBooks[chainId]
    const validAddresses = pickBy(chainAddresses, (_, key) => validateAddress(key) === undefined)
    return chainId ? validAddresses || {} : {}
  },
)
