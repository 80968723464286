import { EthersAdapter, type ContractNetworksConfig } from '@safe-global/protocol-kit'
import { Safe4337Pack, ethers as ethersV6, type InheritWalletOptions } from '@tunghm/relay-kit'
import useWallet from '@/hooks/wallets/useWallet'
import { useState, useEffect } from 'react'
import { getInitCode } from '@/utils/inherit-manager'
import useOwnedSafe from '@/hooks/useOwnedSafe'
import contracts from '@/public/contracts/contractNetwork.json'
import { useCurrentChain } from '@/hooks/useChains'
import { getRpcServiceUrl } from '@/hooks/wallets/web3'
import { walletFromMnemonic } from '@/utils/wallet'
import useHDWallet from '@/hooks/wallets/useHDWallet'
import { PIMLICO_API_KEY } from '@/config/constants'

export const use4337Pack = (): Safe4337Pack | undefined => {
  const [pack, setPack] = useState<Safe4337Pack | undefined>(undefined)
  const wallet = useWallet()
  const { safe } = useOwnedSafe()
  const chain = useCurrentChain()
  const hdWallet = useHDWallet()

  useEffect(() => {
    if (!wallet || !safe || !chain || hdWallet.mnemonic === '') {
      setPack(undefined)
      return
    }
    const BUNDLER_URL = `https://api.pimlico.io/v2/${wallet.chainId}/rpc?apikey=${PIMLICO_API_KEY}`

    const initCode = getInitCode(wallet.chainId, wallet.address)
    const contractNetworks: ContractNetworksConfig = contracts as ContractNetworksConfig
    const inheritOptions: InheritWalletOptions = {
      inheritWallet: safe,
      initCode,
      version: '1.4.1',
      contractNetworks,
    }
    const accountIndex = 0
    const activeWallet = walletFromMnemonic(hdWallet.mnemonic, accountIndex)
    const rpcUrl = getRpcServiceUrl(chain.publicRpcUri)
    const provider = new ethersV6.JsonRpcProvider(rpcUrl)
    const signer = new ethersV6.Wallet(activeWallet.privateKey, provider)

    const ethersAdapter = new EthersAdapter({
      ethers: ethersV6,
      signerOrProvider: signer,
    })

    Safe4337Pack.init(
      {
        ethersAdapter,
        rpcUrl,
        bundlerUrl: BUNDLER_URL,
        options: {
          owners: [wallet.address],
          threshold: 1,
        },
      },
      inheritOptions,
    ).then((pack) => {
      setPack(pack)
    })
  }, [wallet, safe, chain, hdWallet])

  return pack
}
export default use4337Pack
