import { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useDesktop = () => {
  const theme = useTheme()
  const isDesktop = !useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    window.isDesktop = isDesktop
  }, [isDesktop])

  return isDesktop
}

export default useDesktop
