import type { ReactElement, ReactNode } from 'react'
// import { useEffect } from 'react'
// import { Button } from '@mui/material'
import useSafeInfo from '@/hooks/useSafeInfo'
import PagePlaceholder from '../PagePlaceholder'
// import { AppRoutes } from '@/config/routes'
// import Link from 'next/link'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined'
// import { useRouter } from 'next/router'

const SafeLoadingError = ({ children }: { children: ReactNode }): ReactElement => {
  // const router = useRouter()
  const { safeError, safeLoading } = useSafeInfo()

  if (!safeError) return <>{children}</>

  return (
    <PagePlaceholder
      img={<GppMaybeOutlinedIcon sx={{ fontSize: '80px', color: 'var(--Ebony-Clay-600)' }} />}
      text="The Inherit wallet couldn't be loaded"
    >
      {/* <Link href={AppRoutes.index} passHref legacyBehavior>
        <Button variant="contained" size="large" sx={{ mt: 2 }}>
          Try reloading
        </Button>
      </Link> */}
    </PagePlaceholder>
  )
}

export default SafeLoadingError
