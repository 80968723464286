import { useEffect } from 'react'
import { type OwnedSafes } from '@safe-global/safe-gateway-typescript-sdk'

import useLocalStorage from '@/services/local-storage/useLocalStorage'
import useWallet from '@/hooks/wallets/useWallet'
import { Errors, logError } from '@/services/exceptions'
import useChainId from './useChainId'
import { getInheritManager } from '@/utils/contracts'

const CACHE_KEY = 'ownedSafes'

type OwnedSafesCache = {
  [walletAddress: string]: {
    [chainId: string]: OwnedSafes['safes']
  }
}

const useOwnedSafes = (): OwnedSafesCache['walletAddress'] => {
  const chainId = useChainId()
  const { address: walletAddress } = useWallet() || {}
  const [ownedSafesCache, setOwnedSafesCache] = useLocalStorage<OwnedSafesCache>(CACHE_KEY)

  useEffect(() => {
    if (!walletAddress || !chainId) return
    let isCurrent = true

    if (isCurrent) {
      const inheritManager = getInheritManager(chainId)

      inheritManager
        .then((contract) => {
          contract.getInheritWalletOf(walletAddress, 0).then((wallet) => {
            if (!wallet[0]) throw new Error('No wallets found')

            setOwnedSafesCache((prev) => ({
              ...prev,
              [walletAddress]: {
                ...(prev?.[walletAddress] || {}),
                [chainId]: [wallet[0]],
              },
            }))
          })
        })
        .catch((error: Error) => logError(Errors._610, error.message))
    }

    /**
     * No useAsync in this case to avoid updating
     * for a new chainId with stale data see https://github.com/safe-global/safe-wallet-web/pull/1760#discussion_r1133705349
     */
    // getOwnedSafes(chainId, walletAddress)
    //   .then((ownedSafes) => {
    //     if (!isCurrent) return

    //     setOwnedSafesCache((prev) => ({
    //       ...prev,
    //       [walletAddress]: {
    //         ...(prev?.[walletAddress] || {}),
    //         [chainId]: ownedSafes.safes,
    //       },
    //     }))
    //   })
    //   .catch((error: Error) => logError(Errors._610, error.message))

    return () => {
      isCurrent = false
    }
  }, [chainId, walletAddress, setOwnedSafesCache])

  return ownedSafesCache?.[walletAddress || ''] ?? {}
}

export default useOwnedSafes
