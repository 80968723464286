import type { MouseEvent } from 'react'
import { useState } from 'react'
import { Box, ButtonBase, Paper, Popover, Grid } from '@mui/material'
import css from '@/components/common/ConnectWallet/styles.module.css'
import ExpandLessIcon from '@/public/images/icons/arrow-drop-down.svg'
import ExpandMoreIcon from '@/public/images/icons/arrow-drop-down.svg'
import { type ConnectedWallet } from '@/hooks/wallets/useOnboard'
import WalletOverview from '../WalletOverview'
import WalletInfo from '@/components/common/WalletInfo'
import useDesktop from '@/hooks/useDesktop'

export const AccountCenter = ({ wallet }: { wallet: ConnectedWallet }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { balance } = wallet
  const isDesktop = useDesktop()

  const openWalletInfo = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeWalletInfo = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <ButtonBase
        onClick={openWalletInfo}
        aria-describedby={id}
        disableRipple
        sx={{ alignSelf: 'stretch' }}
        data-testid="open-account-center"
      >
        <Grid className={css.buttonContainer} mr={isDesktop ? '0px' : '16px'}>
          <WalletOverview wallet={wallet} balance={balance} showBalance />

          {isDesktop && (
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginLeft="auto">
              {open ? <ExpandLessIcon color="border" /> : <ExpandMoreIcon color="border" />}
            </Box>
          )}
        </Grid>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeWalletInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& > .MuiPaper-root': {
            top: 'var(--header-height) !important',
            width: isDesktop ? 'auto' : '100%',
          },
        }}
        transitionDuration={0}
      >
        <Paper className={css.popoverContainer}>
          <WalletInfo wallet={wallet} handleClose={closeWalletInfo} balance={balance} />
        </Paper>
      </Popover>
    </>
  )
}

export default AccountCenter
